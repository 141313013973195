import React, { useEffect, useState } from "react";
import Possession from "../components/Possessions/Possession";
import Cardimag from "../images/img.png";
import Buy from "../images/icon7.svg";
import pdf from "../images/pdf.svg";
import { fireToast } from "../common/Toster";
import { ethers } from "ethers";
import axios from "axios";
import { getallProperties } from "../services/propertyServices";
import {
  getNftsBuyedByUser,
  getFredsBuyedByUser,
} from "../services/NftServices";
import { useLocation } from "react-router-dom";
import { dateFormat } from "../common/utility";
import {
  getPossession,
  getLeaseTransaction,
} from "../services/propertyServices";
import moment from "moment";
import Loader from "../components/loader/loader";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import NoData from "../components/NoDataComp/NoData";
const Possessions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location.state, "123");
  const [page, setPage] = useState(1);

  const [loading, setLoading] = useState(true);
  const [possessionData, setPossessionData] = useState([]);
  const [possessions, setPossessions] = useState([]);

  const possessionlist = async () => {
    try {
      await getPossession(page)
        .then((res) => {
          setPossessionData(res);
          // console.log(res, "resss");
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    possessionlist();
  }, [page]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="ps-4">
        <div className="dashboard-centerd">
          <div className="row g-0">
            <div className="col-xl-12 col-xxl-12 pe-4 pt-4 border-end center-height pb-5">
              <div className="">
                <div className="row  mb-4">
                  <div className="col-12 d-flex align-items-center justify-content-between mb-4">
                    <h6 className="fw-600 text-black"> Possessions-List</h6>
                  </div>
                  {possessionData?.data?.data?.length > 0 ? (
                    possessionData?.data?.data?.map((item) => (
                      <div
                        className="col-xxl-4 col-xl-4 col-md-6 col-12 mb-4"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate("/possession-details", {
                            state: item?.propertyId,
                          })
                        }
                      >
                        <div className="posse-card">
                          <div className="posse-img position-relative">
                            <img
                              src={
                                item?.imageURL == null
                                  ? Cardimag
                                  : item?.imageURL
                              }
                              alt=""
                            />
                            <div className="property-fee position-absolute p-3 d-flex align-items-center justify-content-between">
                              <div>
                                <p className="f-15 fw-500 text-white mb-0">
                                  Monthly Fees
                                </p>
                                <p className="f-15 fw-500 text-white mb-0 text-green">
                                  {item?.transactionDetails?.totalRedToken} RED
                                </p>
                              </div>
                              <div className="text-end">
                                <p className="f-15 fw-500 text-white mb-0">
                                  Due On
                                </p>
                                <p className="f-15 fw-500 text-white mb-0">
                                  {item.leaseExpirationDate}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="posse-content p-3">
                            <h6 className="mb-0">{item?.propertyName}</h6>
                            <p className="f-14">
                              Price <strong>${item?.totalValue}</strong>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <NoData />
                  )}
                </div>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  {possessionData?.data?.data?.length > 0 ? (
                    <Pagination
                      color="primary"
                      count={Math.ceil(possessionData?.data?.totalCount / 8)}
                      page={page}
                      onChange={(e, v) => setPage(v)}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Possessions;

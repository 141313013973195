import { useAddress } from '@thirdweb-dev/react';
import React from 'react'
import { Modal } from 'react-bootstrap';
import { IoCloseSharp } from 'react-icons/io5';
import { fireToast } from '../../common/Toster';
import { FaCopy } from 'react-icons/fa6';

function ReceiveUsdc({
    userInfo,
    showReceive,
    setShowReceive
}) {
    const address = useAddress();
    return (
        <Modal
            centered
            show={showReceive}
            animation={false}
        >
            <Modal.Body>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <span
                            style={{ cursor: "pointer" }}
                            className="back-btn fw-500 text-black "
                        // onClick={() => setShowReceive(false)}
                        >
                            {/* <i className="pe-2 align-middle">
                <FaCircleChevronLeft />
              </i> */}
                            Receive USDC
                        </span>
                    </div>
                    <div>
                        <span
                            onClick={() => setShowReceive(false)}
                            style={{ cursor: "pointer", fontSize: "22px" }}
                        >
                            <IoCloseSharp />
                        </span>
                    </div>
                </div>

                <div className="px-2 text-center my-4">
                    <p className="mt-2 mb-4">
                        User-Name :{" "}
                        <span className="fw-600 text-black">{userInfo?.userName} </span>
                        {/* <i className="text-black">
                  <FaCopy />
                </i> */}
                    </p>
                    <p className="mt-2 mb-4">
                        Wallet Address :{" "}
                        <span className="fw-600 text-black">
                            {address?.toString()?.slice(0, 6)}...
                            {address?.toString()?.slice(36, 42)}{" "}
                        </span>
                        <i
                            className="text-black"
                            onClick={() => {
                                navigator.clipboard.writeText(address);
                                fireToast("success", "Copied!");
                            }}
                        >
                            <FaCopy />
                        </i>
                    </p>
                    <div>
                        <img
                            width={200}
                            height={200}
                            src={`https://api.qrserver.com/v1/create-qr-code/?data=${address}&amp;size=50x50`}
                            alt=''
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ReceiveUsdc

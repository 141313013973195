import React from "react";
// import moment from "moment";
import momentTimeZone from "moment-timezone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const HRNumbers = require("approximate-number");

export const StandardPickerDateFormat = (Date) => {
  const Los_Angeles_Date = momentTimeZone.utc(Date).tz('America/Los_Angeles')
  return Los_Angeles_Date.format("MMM DD, YYYY");
};

export const fullDateFormat = (date) => {
  const Los_Angeles_Date = momentTimeZone.utc(date).tz('America/Los_Angeles')
  return (
    <>
      {Los_Angeles_Date.format("MMMM DD, YYYY")}
      <br />
      <small>{Los_Angeles_Date.format("h:mm a")}</small>
    </>
  );
};

export const dateFormat = (date) => {
  const Los_Angeles_Date = momentTimeZone.utc(date).tz('America/Los_Angeles')
  return (
    <>
      {Los_Angeles_Date.format("D MMM YYYY")}
      {/* <br /> */}
      <small>({Los_Angeles_Date.format("h:mm a")})</small>
    </>
  );
};

export const downloadFile = (dataurl, filename) => {
  const a = document.createElement("a");
  a.href = dataurl;
  a.setAttribute("download", filename);
  a.click();
};

export const monthDayYearFormat = (date) => {
  const Los_Angeles_Date = momentTimeZone.utc(date).tz('America/Los_Angeles')
  return <>{Los_Angeles_Date.format("MMMM DD, YYYY")}</>;
};

export const Tostify = (massage) => {
  toast(massage);
};
export const humanReadableNumber = (number) => {
  return HRNumbers(number, { capital: true });
};
export function Capatalize(string) {
  return string
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : null;
}
export function IsWalletAddressesEqual(address1, address2) {
  return `${address1}`.toLowerCase() === `${address2}`.toLowerCase();
}
import React from "react";
import { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./loader.css";
import solosLoader from "../../images/solosLoader.gif";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#0052ff",
};
const Loader = () => {
  return (
    <div className="loader-page">
      <img src={solosLoader} style={{ width: "100px", height: "100px" }} />

      {/* <ClipLoader
        cssOverride={override}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      /> */}
    </div>
  );
};

export default Loader;

import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import RedToken from "../components/Red-Token/RedToken";
import SolosReward from "../components/SolosReward/SolosReward";
import BooqBalance from "../components/BooqBalance/BooqBalance";
import Transactions from "../components/Transactions/Transactions";
import {
  getRedTrasactions,
  getSrTrasactions,
} from "../services/propertyServices";
import UserAssets from "./UserAssets";
import MyAssets from "../components/MyAssets";
import { ConnectWallet, useAddress } from "@thirdweb-dev/react";
import Loader from "../components/loader/loader";
import {
  getSoloAssets,
  //  walletData
} from "../services/propertyServices";
import FierceCard from "../components/FierceCard/FierceCard";
import UsdcCard from "../components/Usdc-Card/Usdc";

const Accounts = () => {
  const address = useAddress();
  const [loader, setLoader] = useState(true);
  const [items, setItems] = useState([]);
  const [Default, setDefault] = useState("Red");
  const [apiData, setApiData] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  // useEffect(()=>{
  //   if(Default == "Red"){
  //     getRedTrasactions().then((res)=>{
  //       // console.log(res?.data,"ressss")
  //       setApiData(res?.data)
  //     }).catch((err)=>{
  //       console.log(err,"errrr")
  //     })
  //   }
  //   else if( Default == "Solos"){
  //     getSrTrasactions().then((res)=>{
  //       // console.log(res?.data,"ressss")
  //       setApiData(res?.data)
  //     }).catch((err)=>{
  //       console.log(err,"errrr")
  //     })
  //   }
  //   else{
  //     console.log("boq apiii")
  //   }

  // },[Default])

  const getUserInfoLocal = async () => {
    try {
      let userInfo = localStorage.getItem("user-info") || null;
      userInfo = userInfo ? JSON.parse(userInfo) : null;
      setUserInfo(userInfo?.data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getUserInfoLocal();
  }, []);

  const getTokenBalance = async () => {
    setLoader(true);
    await getSoloAssets(address)
      .then((response) => {
        // console.log(response, "getSoloAssets");
        setItems(response?.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  };

  useEffect(() => {
    if (address) {
      getTokenBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);
  const redTokenData = items?.find((item) => item?.symbol === "RED");
  const SRTokenData = items?.find((item) => item?.symbol === "SR");
  const UsdcTokenData = items?.find((item) => item?.symbol === "USDC");

  return loader ? (
    <Loader />
  ) : (
    <>
      <div className="ps-4">
        <div className="dashboard-centerd">
          <div className="row g-0">
            <div className="col-xl-8 col-xxl-9 pe-4 pt-4 border-end center-height">
              <div className="row mb-5">
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    {/* <h6 className="fw-600 text-black">Transactions</h6> */}
                    {/* <Link className="view-btn" to="/">
                      view all <BiChevronRight />
                    </Link> */}
                  </div>
                </div>
                <div
                  className="col-xxl-3 col-lg-6 col-12 "
                  style={{ cursor: "pointer" }}
                  onClick={() => setDefault("Red")}
                >
                  <RedToken
                    redTokenData={redTokenData}
                    getTokenBalance={getTokenBalance}
                  />
                </div>
                <div
                  className="col-xxl-3 col-lg-6 col-12"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDefault("Solos")}
                >
                  <SolosReward
                    SRTokenData={SRTokenData}
                    getTokenBalance={getTokenBalance}
                  />
                </div>
                <div
                  className="col-xxl-3 col-lg-6 col-12"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDefault("Usdc")}
                >
                  <UsdcCard
                    userInfo={userInfo}
                    UsdcTokenData={UsdcTokenData}
                    getTokenBalance={getTokenBalance}
                  />
                </div>
                <div
                  className="col-xxl-3 col-lg-6 col-12 mt-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDefault("Booq")}
                >
                  <FierceCard userInfo={userInfo} />
                  {/* <BooqBalance
                    getTokenBalance={getTokenBalance}
                    userInfo={userInfo}
                    UsdcTokenData={UsdcTokenData}
                  /> */}
                </div>
              </div>
              {/* <div className="col-12">
                <Transactions data={Default} />

              </div> */}
              <div className="col-12">
                <UserAssets />
              </div>
            </div>
            <div className="col-xl-4 col-xxl-3">
              <div className="right-sidebar px-xl-3 pe-4 pt-lg-5">
                <MyAssets />
                {/* <Transactions data={Default} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accounts;

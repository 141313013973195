import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./custom.css";
import { useFormik } from "formik";
import { ConversionContext } from "../Context/context";
import * as Yup from "yup";
import { saveBasicInfo } from "../services/userService";
import { fireToast } from "../common/Toster";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
const BasicInfo = ({ setShow }) => {
  let userInfo = localStorage.getItem("reg-data") || null;
  userInfo = userInfo ? JSON.parse(userInfo) : null;
  const contextData = useContext(ConversionContext);
  const { setShowKyc } = contextData;
  const getUserData = contextData?.getUserData;
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: userInfo?.data?._id,
      name: "",
      lastname: "",
      birth_date: "",
      countryShortCode: "USA",
      city: "",
      postal_code: "",
      state: "",
      street_line_1: "",
      street_line_2: "",
      contact: "",
      countryCode: "",
      tax_identification_number: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(30, "First Name must be less than 30 characters")
        .required("First Name is Required"),
      lastname: Yup.string()
        .max(30, "Last Name must be less than 30 characters")
        .required("Last Name is Required"),
      birth_date: Yup.date().required("Birth Date is Required"),
      countryShortCode: Yup.string()
        .max(3)
        .uppercase()
        .required("Country is Required"),
      city: Yup.string().required("City is Required"),
      postal_code: Yup.string()
        .required("Postal Code is Required")
        .min(5, "Postal Code must be minimum 5 characters long")
        .max(7, "Postal Code must be maximum 7 characters long"),
      state: Yup.string().max(3).uppercase().required("State is Required"),
      street_line_1: Yup.string().required("Address Line 1 is Required"),
      tax_identification_number: Yup.string().required(
        "SSN or Taxpayer ID Number is Required"
      ),
      contact: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required("A phone number is required"),
      countryCode: Yup.number()
        .typeError("That doesn't look like a Phone Code")
        .positive("Phone Code can't start with a minus")
        .integer("Phone Code can't include a decimal point")
        .required("Phone Code is Required"),
    }),
    onSubmit: async (values) => {
      try {
        const loginResponse = await saveBasicInfo(values);
        if (loginResponse.status === 200) {
          getUserData();
          fireToast("success", loginResponse.message);
          localStorage.setItem("user-info", JSON.stringify(loginResponse));
          setShow(false);
          setShowKyc(true);
        } else {
          throw new Error(loginResponse.error);
        }
      } catch (err) {
        console.log(err, ">>>>>>>>>>>>>>>");
        fireToast("error", err?.response?.data?.error);
      }
    },
  });
  return (
    <div className="">
      <div className="">
        <div className="basicInfo-inner">
          <div className="text-center mb-2">
            <h1 className="fs-32 my-3">Complete Profile</h1>
          </div>
          <hr />
          <Form
            className="container"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="row">
              <h4 className="my-2">Basic Information</h4>
              <div className="mb-3 user-form col-md-4">
                <Form.Group className="position-relative">
                  <Form.Label className="form-label">First Name</Form.Label>
                  <Form.Control
                    name="name"
                    className="form-ctl "
                    placeholder={"Enter First Name"}
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <div className="text-danger error-msg">
                      {validation.errors.name}
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="mb-3 col-md-4">
                <Form.Group className="position-relative">
                  <Form.Label className="form-label">Last Name</Form.Label>
                  <Form.Control
                    className="form-ctl "
                    name="lastname"
                    placeholder={"Enter Last Name"}
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.lastname || ""}
                    invalid={
                      validation.touched.lastname && validation.errors.lastname
                        ? "true"
                        : "false"
                    }
                  />
                  {validation.touched.lastname && validation.errors.lastname ? (
                    <div className="text-danger error-msg">
                      {validation.errors.lastname}
                    </div>
                  ) : null}
                </Form.Group>
              </div>

              <div className="mb-3 col-md-4">
                <Form.Group className="position-relative">
                  <Form.Label className="form-label">
                    Phone Number
                    {/* <span style={{ fontSize: "xx-small" }}>(enter without phone code)</span> */}
                  </Form.Label>
                  <PhoneInput
                    className="form-ctl form-control"
                    defaultCountry="US"
                    countryCallingCodeEditable={false}
                    placeholder="Enter Phone Number"
                    international
                    numberInputProps={{ name: "contact" }}
                    onChange={(contact) => {
                      let data = parsePhoneNumber(`${contact}`);
                      // console.log(data);
                      validation.setFieldTouched("contact", true);
                      validation.setFieldTouched("countryCode", true);
                      validation.setFieldValue("contact", data?.nationalNumber);
                      validation.setFieldValue(
                        "countryCode",
                        `+${data?.countryCallingCode}`
                      );
                    }}
                  />
                  {validation.touched.contact && validation.errors.contact ? (
                    <div className="text-danger error-msg">
                      {validation.errors.contact}
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="mb-3 col-md-4">
                <Form.Group className="position-relative">
                  <Form.Label className="form-label">Birth Date</Form.Label>
                  <Form.Control
                    className="form-ctl "
                    name="birth_date"
                    placeholder={"Enter Birth Date"}
                    // min={new Date("1-1-1900").toISOString()?.substring(0, 10)}
                    max={new Date().toISOString()?.substring(0, 10)}
                    type="date"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.birth_date &&
                      validation.errors.birth_date
                        ? true
                        : false
                    }
                  />
                  {validation.touched.birth_date &&
                  validation.errors.birth_date ? (
                    <div className="text-danger error-msg">
                      {validation.errors.birth_date}
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="mb-3 col-md-4">
                <Form.Group className="position-relative">
                  <Form.Label className="form-label">
                    SSN or Taxpayer ID
                  </Form.Label>
                  <Form.Control
                    className="form-ctl "
                    name="tax_identification_number"
                    placeholder={"SSN or Taxpayer ID"}
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.tax_identification_number &&
                  validation.errors.tax_identification_number ? (
                    <div className="text-danger error-msg">
                      {validation.errors.tax_identification_number}
                    </div>
                  ) : null}
                </Form.Group>
              </div>

              <h4 className="my-2">Address</h4>
              <div className="mb-3 col-md-4">
                <Form.Group className="position-relative">
                  <Form.Label className="form-label">Address Line 1</Form.Label>
                  <Form.Control
                    className="form-ctl "
                    name="street_line_1"
                    placeholder={"Enter Address Line 1"}
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.street_line_1 &&
                  validation.errors.street_line_1 ? (
                    <div className="text-danger error-msg">
                      {validation.errors.street_line_1}
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="mb-3 col-md-4">
                <Form.Group className="position-relative">
                  <Form.Label className="form-label">Address Line 2</Form.Label>
                  <Form.Control
                    className="form-ctl "
                    name="street_line_2"
                    placeholder={"Enter Address Line 2"}
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                </Form.Group>
              </div>
              <div className="mb-3 col-md-4">
                <Form.Group className="position-relative">
                  <Form.Label className="form-label">Postal Code</Form.Label>
                  <Form.Control
                    className="form-ctl "
                    name="postal_code"
                    placeholder={"Enter Postal Code"}
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.postal_code &&
                  validation.errors.postal_code ? (
                    <div className="text-danger error-msg">
                      {validation.errors.postal_code}
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="mb-3 col-md-4">
                <Form.Group className="position-relative">
                  <Form.Label className="form-label">
                    City
                    <br className="d-none d-sm-block" />
                    <br />
                  </Form.Label>
                  <Form.Control
                    className="form-ctl "
                    name="city"
                    placeholder={"Enter City"}
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.city && validation.errors.city ? (
                    <div className="text-danger error-msg">
                      {validation.errors.city}
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="mb-3 col-md-4">
                <Form.Group className="position-relative">
                  <Form.Label className="form-label">
                    State
                    <br />
                    <span style={{ fontSize: "xx-small" }}>
                      (2 letter of code example : NY)
                    </span>
                  </Form.Label>
                  <Form.Control
                    className="form-ctl text-uppercase"
                    name="state"
                    maxLength={2}
                    placeholder={"Enter State"}
                    value={validation.values.state}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.state && validation.errors.state ? (
                    <div className="text-danger error-msg">
                      {validation.errors.state}
                    </div>
                  ) : null}
                </Form.Group>
              </div>

              <div className="mb-3 col-md-4">
                <Form.Group className="position-relative">
                  <Form.Label className="form-label">
                    Country
                    <br />
                    <span style={{ fontSize: "xx-small" }}>
                      (3 letter of code example : USA)
                    </span>
                  </Form.Label>
                  <Form.Control
                    aria-label="Default select example"
                    className="form-ctl text-uppercase"
                    name="countryShortCode"
                    maxLength={3}
                    placeholder={"Enter Country"}
                    value={validation.values.countryShortCode}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.countryShortCode &&
                  validation.errors.countryShortCode ? (
                    <div className="text-danger error-msg">
                      {validation.errors.countryShortCode}
                    </div>
                  ) : null}
                </Form.Group>
              </div>

              <div className="mt-3 d-grid">
                <Button
                  className="common-btn mt-2 mb-3 w-100"
                  variant="primary"
                  type="submit"
                >
                  <strong>Complete</strong>
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;

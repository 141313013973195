import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Loader from "../components/loader/loader";
import NoData from "../components/NoDataComp/NoData";
import Pagination from "@mui/material/Pagination";
import { getAllPlatformTvts } from "../services/NftServices";
import UserTvtCard from "../components/Tvt/UserTvtCard";
import { fireToast } from "../common/Toster";
import { ethers } from "ethers";
import axios from "axios";
import Web3, { providers } from "web3";
import RedTokenAbi from "../Abis/RedTokenAbi.json";
import { useAddress } from "@thirdweb-dev/react-core";

const debounceTime = 300;
let distinctUntilChanged = null;

const ViewAllBuyTvts = () => {
  const MyAddress = useAddress();
  const navigate = useNavigate();
  const [tvtsData, setTvtsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState("");
  const [filter, setFilter] = useState("");
  const fetchuserTvts = async () => {
    try {
      setLoading(true);
      const response = await getAllPlatformTvts(MyAddress);

      if (response.status === 200) {
        const allTvtData = response?.data;
        const allTvtDataWithoutTest = response?.data?.filter(
          (tvt) => !tvt?.["name"]?.toLowerCase()?.includes("test")
        );
        setTvtsData(
          window.location.hostname.includes("localhost") ?
          allTvtData
          : allTvtDataWithoutTest
        );
        setLoading(false);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching nft data:", error);
    }
  };

  const getWallet = async (value) => {
    if (window.web3) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }], // chainId must be in hexadecimal numbers
        });
      } catch (e) {
        // fireToast("error", "Request Rejected.");
      }
    }
    const web3 = (window.web3 = new Web3(window.ethereum));
    var contractInstance = "";
    contractInstance = new web3.eth.Contract(RedTokenAbi, value, providers);
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    var account = accounts?.[0] ? accounts?.[0] : accounts?.result?.[0];
    const RedBalance = await contractInstance._methods
      .balanceOf(account)
      .call();
    // console.log(RedBalance.toString(), "RedBalance");
    let redBal = RedBalance.toString();
    redBal = Number(redBal) / 10 ** 18;
    redBal = Math.round(redBal);
    return redBal;
  };

  const handleSearch = (val) => {
    const value = val.trim();
    setFilter(value);
    if (distinctUntilChanged) {
      clearTimeout(distinctUntilChanged);
      distinctUntilChanged = null;
    }
    distinctUntilChanged = setTimeout(() => {
      setFilter(value);
      fetchuserTvts(1, 8, value);
      distinctUntilChanged = null;
    }, debounceTime);
  };

  useEffect(() => {
    fetchuserTvts();
  }, []);
  return (
    <div className="p-4">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link
          className="back-btn fw-500 text-black py-4"
          onClick={() => navigate(-1)}
        >
          <i className="pe-2 align-middle">
            <FaCircleChevronLeft />
          </i>
          Back
        </Link>
        <div>
          <input
            type="search"
            placeholder="Search TVTs"
            style={{
              borderRadius: "12px",
              border: "none",
              padding: "5px 20px",
              background: "#d3d3d3a1",
            }}
            value={filter}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="dashboard-centerd">
          {tvtsData?.length > 0 ? (
            <div className="row g-0">
              <div className="row mb-4">
                {tvtsData?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="col-xxl-3 col-xl-4 col-md-6 col-12 mb-4"
                    >
                      <UserTvtCard
                        data={item}
                        fetchData={fetchuserTvts}
                        setLoading={setLoading}
                      />
                    </div>
                  );
                })}
              </div>
              {totalData > 12 && (
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    // minWidth: "200%",
                  }}
                >
                  <Pagination
                    color="primary"
                    count={Math.ceil(totalData / 12)}
                    page={page}
                    onChange={(e, v) => setPage(v)}
                  // activePage={page}
                  />
                </div>
              )}
            </div>
          ) : (
            <NoData />
          )}
        </div>
      )}
    </div>
  );
};

export default ViewAllBuyTvts;

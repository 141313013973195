// import { get, post, postAsForm, put } from "helpers/api_helper.js";
import { get, post, postAsForm, put } from "../helpers/api_helper";
import appendData from "../utils/formData";

// Bridge Code
export const generateBrigeLink = () => {
  return get("/bridge/generateSessionLink");
};

export const userRegisterOnBridge = (data) => {
  return post("/bridge/registerBridge", data);
};

export const generateBridgeKycLink = (email) => {
  return get("/bridge/generateKycLink?email=" + email);
};

export const getListOfTransactions = (customer_id) => {
  return get("/bridge/listTransfersOfUser?customer_id=" + customer_id);
};
export const getListOfExternalAcc = (customer_id) => {
  return get("/bridge/listExternalAccount?customer_id=" + customer_id);
};

export const transferFiatToCoin = (data) => {
  return post("/bridge/transferFiatToCoin", data);
};
export const addBankAccountToBridge = (data) => {
  return post("/bridge/createExternalAccount", data);
};
export const withdarwAmountFromBridge = (data) => {
  return post("/bridge/withdrawCoinToFiat", data);
};

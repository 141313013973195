import { get, post, postAsForm, put } from "../helpers/api_helper";
import appendData from "../utils/formData";

export const getAllNfts = (pageNumber, pageSize, search) => {
  return get(
    `/client/getNftDataByUser?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}`
  );
};
export const getAllFreds = (pageNumber, pageSize, search) => {
  return get(
    `/fred/fredDataOfSoloMarketPlace?page_number=${pageNumber}&page_limit=${pageSize}&filter=${search}`
  );
};
export const getAllTvts = (pageNumber, pageSize, search) => {
  return get(
    `/tvt/tvtDataOfSoloMarketPlace?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}`
  );
};
export const getAllSuperFreds = (pageNumber, pageSize, search) => {
  return get(
    `/superFred/sfredDataOfSoloMarketPlace?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}`
  );
};
export const getUserTvts = () => {
  return get(`/tvt/getUserTvt`);
};
export const getAllPlatformTvts = (walletAddress) => {
  return get(`/tvt/getAllTvt?walletAddress=${walletAddress}`);
};
export const getAllTvtsForClients = () => {
  return get(`/tvt/getAllTvtDataForClient`);
};
export const getUserSuperFreds = (pageNumber, pageSize, search) => {
  return get(
    `/superFred/getSFredInMarketPlace?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}`
  );
};
export const transferNFT = (data) => {
  return post("/fred/nftTransfer", data);
};

export const buyTvt = (data) => {
  return post("/tvt/buyTvt", data);
};
export const buySolosTvt = (data) => {
  return post("/tvt/requestTvt", data);
};
export const buySolosFred = (data) => {
  return post("/fred/requestFred", data);
};
export const buySolosSFred = (data) => {
  return post("/superFred/requestSFred", data);
};
export const buySuperFred = (data) => {
  return post("/superFred/buySuperFred", data);
};

export const getConversionRate = () => {
  return get(`/client/buyRedToken`);
};
export const getNftsBuyedByUser = () => {
  return get(`/client/getNftDetailsOfUsers`);
};
export const getFredBuyedByUser = (address) => {
  return get(`/Fred/getAllFred?walletAddress=${address}`);
};
export const getSfredBuyedByUser = (address) => {
  return get(`/superFred/getAllSFred?walletAddress=${address}`);
};
export const getFredsBuyedByUser = () => {
  return get(`/client/getFredNftDetailsOfUsers`);
};
export const getNftCategories = (pageNumber, pageSize) => {
  return get(
    `/admin/getCategory?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
};
export const sellNftOnUserMarketplace = (data) => {
  return put(`/client/sellNftInMarketplace`, data);
};
export const sellSFREDOnUserMarketplace = (data) => {
  return post(`/superFred/SFredSellinMarketPlace`, data);
};
export const sellTvtOnUserMarketplace = (data) => {
  return post(`/tvt/tvtSellInUserMarketPlace`, data);
};
export const sellTvtTokenOnUserMarketplace = (data) => {
  return post(`/tvt/tvttokenSellInUserMarketPlace`, data);
};

export const getUserMarketplaceNfts = (pageNumber, pageSize, search) => {
  return get(
    `/client/getNftDetailSellInMarketplace?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}`
  );
};
export const getUserMarketplaceFreds = (pageNumber, pageSize, search) => {
  return get(
    `/client/getFredNftDetailSellInMarketplace?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}`
  );
};
export const getAllUserMarketplaceFreds = () => {
  return get(`/fred/getAllFredDataForClient`);
};
export const getAllUserMarketplaceSFreds = () => {
  return get(`/superFred/getAllSFredDataForClient`);
};
export const getUserMarketplaceTvts = (pageNumber, pageSize, search) => {
  return get(
    `/tvt/getTvtInUserMarketPlace?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}`
  );
};
export const getUserMarketplaceTvtsTokens = () => {
  return get(`/tvt/getTvtTokenInUserMarketPlace`);
};
export const getUserDataWithWallet = (data) => {
  return get(`/client/getUserDataWithWalletAddress?walletAddress=${data}`);
};
export const buyUserToUserTvt = (data) => {
  return post(`/tvt/saveTvtTransaction`, data);
};
export const buyUserToUserFred = (data) => {
  return post(`/fred/saveFredTransaction`, data);
};
export const buyUserToUserSFred = (data) => {
  return post(`/superFred/saveSFredTransaction`, data);
};

/* eslint-disable eqeqeq */
import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./TvtDetail.css";
import TvtNewAbi from "../../Abis/TvtNewAbi.json";
import TvtNewAbi2 from "../../Abis/TvtNewAbi2.json";
import BridgeAbi from "../../Abis/BridgeAbi.json";
import { ethers } from "ethers";
import { getAllClientUsers } from "../../services/propertyServices";
import { ConversionContext } from "../../Context/context";
import { FaCircleChevronLeft } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import PinConfirm from "../Modals/PinConfirm";
import PinConfirm2 from "../Modals/PinConfirm";
import Button from "react-bootstrap/Button";
import {
  getConversionRate,
} from "../../services/propertyServices";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import WaitingLoader from "../loader/waitingLoader";
import Loader from "../loader/loader";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  fireToast,
  maintenancemodePopup,
  toastConfirm,
} from "../../common/Toster";
import {
  sellTvtTokenOnUserMarketplace,
  buySolosTvt,
  buyUserToUserTvt,
} from "../../services/NftServices";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import configURl from "../../runtime.config";
import { useContract, useAddress, useSigner } from "@thirdweb-dev/react";
const booqNAddress = '0x77C7c46C6385C62Ca6Fec4FFC9eEad4916E47648';
const newTvtAddress = '0x9995c889c38CF8E9b77001351fB6fbE63Bd4F786';

const TvtDetail = () => {
  const myAddress = useAddress();
  const signer = useSigner();
  const { contract } = useContract(configURl.redNewAddress);
  const [openBuy, setOpenBuy] = useState(false);
  const [totalRedAmount, setTotalRedAmount] = useState("");
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [show, setShow] = useState(false);
  const [showSend, setShowSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userFullName, SetUserFullName] = useState("");
  const [loader, setLoader] = useState(false);
  const [waitLoader, setWaitLoader] = useState(false);
  const [showPinModal, setShowPinModal] = useState(false);
  const [showPinModal2, setShowPinModal2] = useState(false);
  const [formData, setFormData] = useState({});
  const [formData2, setFormData2] = useState({});
  const [search1, SetSearch] = useState("");
  const contextData = useContext(ConversionContext);
  const isPinActive = contextData?.userData?.isMpinActive;
  const [adminAddress, setAdminAddress] = useState(0);
  const location = useLocation();
  const data = location?.state?.data;
  const card = location?.state?.card;
  const conversionRate = contextData?.conversionRate;
  const userDataContext = contextData?.userData;
  // console.log(data, "data");
  const handleClose = () => setShow(false);
  const handleCloseSend = () => {
    setShowSend(false);
    SetUserFullName("");
  };
  const handleClosePinModal = () => {
    setShowPinModal(false);
  };
  const handleClosePinModal2 = () => {
    setShowPinModal2(false);
  };
  const handleBuyClose = () => setOpenBuy(false);
  const Schema = Yup.object().shape({
    quantity: Yup.number()
      .required("Please enter Quantity")
      .moreThan(0, "Quantity must be greater than 0")
      // .min(1, "Quantity must be greater than or equal to 1")
      .max(data?.balance, `Only ${data?.balance} TVTs are available`),
    price: Yup.number()
      .moreThan(0, "Price must be greater than 0")
      .required("Please enter price"),
  });
  const BuySchema = Yup.object().shape({
    quantity: Yup.number()
      .required("Please enter Quantity")
      .min(1, "Quantity must be greater than or equal to 1")
      .max(
        data?.quantityForSellInSoloMarketPlace,
        `Only ${data?.quantityForSellInSoloMarketPlace} TVTs are available`
      ),
  });

  //sell on user-marketplace
  const onSubmit = async (values) => {
    (isPinActive
      ? () => {
        setShow(false);
        setFormData2(values);
        setShowPinModal2(true);
      }
      : () => {
        onSellFunction(values);
      })();
  };
  const checkAndDoApproval = async () => {
    const booqNContract = new ethers.Contract(
      booqNAddress,
      TvtNewAbi,
      signer
    );
    let booqNbalance = await booqNContract.balanceOf(myAddress);
    const booqNdecimals = await booqNContract.decimals();
    booqNbalance = ethers.utils.formatUnits(booqNbalance, booqNdecimals);
    console.log(booqNbalance, 'booqNbalance');
    let booqNapprovedAmount = await booqNContract.allowance(
      myAddress,
      configURl.bridgeContractAddress
    );
    booqNapprovedAmount = ethers.utils.formatUnits(
      booqNapprovedAmount,
      booqNdecimals
    );
    const gasPrice = ethers.utils.parseUnits("500", "gwei");
    console.log(booqNapprovedAmount, "booqNapprovedAmount");
    if (+booqNapprovedAmount < +booqNbalance) {
      console.log("ask for booqN approve");
      const approveBooqNTrnx = await booqNContract.approve(
        configURl.bridgeContractAddress,
        "1000000000000000000000000",
        {
          maxFeePerGas: gasPrice,
          maxPriorityFeePerGas: gasPrice,
          gasLimit: 600000,
        }
      );
      console.log(approveBooqNTrnx, "approving BooqN...");
      const approvedBooqN = await approveBooqNTrnx.wait();
      console.log(approvedBooqN, "booqN approval done");
    }
    const newTvtContract = new ethers.Contract(
      newTvtAddress,
      TvtNewAbi,
      signer
    );
    let newTvtApprovedAmount = await newTvtContract.allowance(
      myAddress,
      configURl.bridgeContractAddress
    );
    const decimals = await newTvtContract.decimals();
    newTvtApprovedAmount = ethers.utils.formatUnits(
      newTvtApprovedAmount,
      decimals
    );
    console.log(newTvtApprovedAmount, "newTvtApprovedAmount");
    if (+newTvtApprovedAmount < +booqNbalance) {
      console.log("ask for newTvt approve");
      const approveNewTvtTrnx = await newTvtContract.approve(
        configURl.bridgeContractAddress,
        "1000000000000000000000000",
        {
          maxFeePerGas: gasPrice,
          maxPriorityFeePerGas: gasPrice,
          gasLimit: 600000,
        }
      );
      console.log(approveNewTvtTrnx, "approving newTvt...");
      const approvedNewTvt = await approveNewTvtTrnx.wait();
      console.log(approvedNewTvt, "newTvt approval done");
    }
    let bridgeNewTvtbalance = await newTvtContract.balanceOf(configURl.bridgeContractAddress);
    bridgeNewTvtbalance = ethers.utils.formatUnits(bridgeNewTvtbalance, decimals);
    console.log(bridgeNewTvtbalance, booqNbalance, 'bridgeNewTvtbalance & booqNbalance');
    if (+bridgeNewTvtbalance < +booqNbalance) {
      throw new Error('Try Again Later Bridge New BooqN Balance is Low !')
    }
  }
  const updateTvtFunction = async () => {
    toastConfirm(
      "Are you sure?",
      `You want to upgrade your BOOQN TVT ?`
    )
      .fire()
      .then(async (val) => {
        if (val.isConfirmed) {
          try {
            setWaitLoader(true);
            setLoading(true);
            const gasPrice = ethers.utils.parseUnits("500", "gwei");
            const maticBalance = ethers.utils.formatEther(await signer.getBalance());
            // const estimatedGasPrice = ethers.utils.formatEther(gasPrice);
            if (+maticBalance < configURl.minMaticLimit) {
              fireToast("error", "Insufficient matic funds for gas price !");
              setWaitLoader(false);
              setLoading(false);
              return;
            }
            try {
              await checkAndDoApproval();
            } catch (error) {
              fireToast("error", error?.message);
              setLoading(false);
              setWaitLoader(false);
              return;
            }
            const bridgeContract = new ethers.Contract(
              configURl.bridgeContractAddress,
              BridgeAbi,
              signer
            );
            const bridgeTrnx = await bridgeContract.claimNewTVT({
              maxFeePerGas: gasPrice,
              maxPriorityFeePerGas: gasPrice,
              gasLimit: 600000
            });
            console.log(bridgeTrnx, 'claimNewTVT Trnx..');
            setLoading(false);
            setWaitLoader(false);
            fireToast(
              "success",
              "TVT upgraded successfully."
            );
            navigate("/accounts");
          } catch (error) {
            console.error("Error claiming New TVT:", error);
            setLoading(false);
            setWaitLoader(false);
            fireToast("error", "Something went wrong please try again later !");
          }
        }
      });
  };
  const onSellFunction = async (values) => {
    try {
      setWaitLoader(true);
      setLoading(true);
      const gasPrice = ethers.utils.parseUnits("500", "gwei");
      const maticBalance = ethers.utils.formatEther(await signer.getBalance());
      // const estimatedGasPrice = ethers.utils.formatEther(gasPrice);
      if (+maticBalance < configURl.minMaticLimit) {
        fireToast("error", "Insufficient matic funds for gas price !");
        setWaitLoader(false);
        setLoading(false);
        return;
      }
      const tvtContract = new ethers.Contract(
        data?.contractAddress,
        TvtNewAbi,
        signer
      );
      const decimals = await tvtContract.decimals();
      const newAmount = ethers.utils.parseUnits(
        values?.quantity?.toString(),
        decimals
      );
      const newPrice = data?.isPurchasable ? ethers.utils.parseUnits(
        values?.price?.toString(),
        decimals
      ) : values?.price?.toString();
      await tvtContract
        .listTvt(newPrice, newAmount, {
          maxFeePerGas: gasPrice,
          maxPriorityFeePerGas: gasPrice,
          gasLimit: 600000,
        })
        .then(async (response) => {
          console.log(response, "listTvt Trnx..");
          await sellTvtTokenOnUserMarketplace({
            tradePrice: values?.price,
            tvtId: data?._id,
            quantity: values?.quantity,
            walletAddress: myAddress,
          })
            .then(async (res) => {
              setLoading(false);
              setWaitLoader(false);
              // console.log(res, "res????????????????");
              fireToast(
                "success",
                "TVT listed successfully but it will take some time."
              );
              navigate("/accounts");
              // navigate("/marketplace", { state: { data: "usermarket" } });
            })
            .catch((errApi) => {
              setLoading(false);
              setWaitLoader(false);
              fireToast("error", errApi);
            });
        });
    } catch (error) {
      console.error("Error fetching tvt data:", error);
      setLoading(false);
      setWaitLoader(false);
      fireToast("error", "Something went wrong please try again later !");
    }
  };

  const onBuy = (values) => {
    (isPinActive
      ? () => {
        setOpenBuy(false);
        setFormData(values);
        setShowPinModal(true);
      }
      : () => {
        onBuyFunction(values);
      })();
  };

  const onBuyFunction = async (values) => {
    try {
      console.log(data, "tvt data");
      setWaitLoader(true);
      setLoading(true);
      const gasPrice = ethers.utils.parseUnits("500", "gwei");
      const maticBalance = ethers.utils.formatEther(await signer.getBalance());
      // const estimatedGasPrice = ethers.utils.formatEther(gasPrice);
      if (+maticBalance < configURl.minMaticLimit) {
        fireToast("error", "Insufficient matic funds for gas price !");
        setWaitLoader(false);
        setLoading(false);
        return;
      }
      let amount = `${values?.quantity * data?.tradePrice}`;
      let balance = await contract.erc20.balanceOf(myAddress);
      if (+balance.displayValue < +amount) {
        fireToast("error", "Transfer amount exceeds balance !");
        setLoading(false);
        setWaitLoader(false);
        return;
      }
      const redContract = new ethers.Contract(
        configURl?.redNewAddress,
        contract.abi,
        signer
      );
      const redDecimals = await redContract.decimals();
      const approvedAmount = await redContract.allowance(
        myAddress,
        data?.contractAddress
      );
      let formattedApprovedAmount = ethers.utils.formatUnits(
        approvedAmount,
        redDecimals
      );
      console.log(formattedApprovedAmount, "formattedApprovedAmount");
      if (+formattedApprovedAmount < +amount) {
        console.log("ask for approve");
        const approveTrnx = await redContract.approve(
          data?.contractAddress,
          "1000000000000000000000000",
          {
            maxFeePerGas: gasPrice,
            maxPriorityFeePerGas: gasPrice,
            gasLimit: 600000,
          }
        );
        console.log(approveTrnx, "approving...");
        const approved = await approveTrnx.wait();
        console.log(approved, "approval done");
      }
      const tvtContract = new ethers.Contract(
        data?.contractAddress,
        data?.isPurchasable ? TvtNewAbi2 : TvtNewAbi,
        signer
      );
      const decimals = await tvtContract.decimals();
      const tvtNewAmount = ethers.utils.parseUnits(
        values?.quantity?.toString(),
        decimals
      );
      let mintTrnx;
      if (data?.isPurchasable) {
        mintTrnx = await tvtContract.buyTokens(tvtNewAmount, {
          maxFeePerGas: gasPrice,
          maxPriorityFeePerGas: gasPrice,
          gasLimit: 600000,
        });
        console.log(mintTrnx, "buyTokens");
      } else {
        mintTrnx = await tvtContract.mint(tvtNewAmount, {
          maxFeePerGas: gasPrice,
          maxPriorityFeePerGas: gasPrice,
          gasLimit: 600000,
        });
        console.log(mintTrnx, "mintTrnx");
      }

      // const tx = await contract.erc20.transfer(adminAddress, amount);
      // const response = tx.receipt;
      // console.log(response, "redresponse");
      // if (response.status) {
      // console.log(response.status, ">>>>>>>>");
      // console.log(values, "values");

      // setLoading(true);
      await buySolosTvt({
        contractAddress: data?.contractAddress,
        hashId: mintTrnx?.hash,
        walletAddress: myAddress,
        quantity: values?.quantity,
        tvtId: data?._id,
        redToken: amount,
      })
        .then(async (res) => {
          setLoading(false);
          setWaitLoader(false);
          fireToast("success", "TVT buy successfully");
          navigate("/marketplace");
        })
        .catch((errApi) => {
          setWaitLoader(false);
          setLoading(false);
          fireToast("error", errApi);
        });
      // }
    } catch (error) {
      setLoading(false);
      setWaitLoader(false);
      console.log(error);
      fireToast("error", "Something went wrong please try again later !");
    }
  };

  const buy = async () => {
    const exChangeRate = await getConversionRate();
    const { receivewalletaddress } = exChangeRate.data;
    setAdminAddress(receivewalletaddress);
    setOpenBuy(true);
  };

  const onSell = async () => {
    const exChangeRate = await getConversionRate();
    const { receivewalletaddress } = exChangeRate.data;
    setAdminAddress(receivewalletaddress);
    setShow(true);
  };

  const onSend = async () => {
    setShowSend(true);
  };

  const SignupSchema = Yup.object().shape({
    userName: Yup.string().required("User name is required"),
    quantity: Yup.number()
      .required("Quantity is required")
      .max(data?.balance, `Only ${data?.balance} TVTs are available`),
    walletAddress: Yup.string()
      .min("42")
      .max("42")
      .required("Address is required"),
  });
  const SendOutsideSchema = SignupSchema.omit('userName');

  const onFormSubmit = async (values, actions) => {
    try {
      setLoader(true);
      const gasPrice = ethers.utils.parseUnits("500", "gwei");
      const maticBalance = ethers.utils.formatEther(await signer.getBalance());
      // const estimatedGasPrice = ethers.utils.formatEther(gasPrice);
      if (+maticBalance < configURl.minMaticLimit) {
        fireToast("error", "Insufficient matic funds for gas price !");
        setLoader(false);
        return;
      }
      const tvtContract = new ethers.Contract(
        data?.contractAddress,
        TvtNewAbi,
        signer
      );
      const decimals = await tvtContract.decimals();
      const newAmount = ethers.utils.parseUnits(
        values?.quantity?.toString(),
        decimals
      );
      const response = await tvtContract
        .transfer(values?.walletAddress, newAmount, {
          maxFeePerGas: gasPrice,
          maxPriorityFeePerGas: gasPrice,
          gasLimit: 600000
        });
      console.log(response, "transfer trnx..");
      await buyUserToUserTvt({
        contractAddress: data?.contractAddress,
        tvtTransactionHash: response?.hash,
        walletAddressFrom: myAddress,
        walletAddressTo: values?.walletAddress,
        amount: "0",
        quantity: values?.quantity?.toString(),
        tvtId: data?._id,
      }).then(async (response) => {
        console.log(response, "api response..");
        fireToast(
          "success",
          "Sent successfully! It will take some time. Please wait!"
        );
        navigate("/accounts");
        setShowSend(false);
        setLoader(false);
      });
    } catch (error) {
      console.error("Error fetching tvt data:", error);
      setLoader(false);
      fireToast("error", "Something went wrong please try again later !");
    }
  };
  const fetchUserData = async (
    pageNumber = 1,
    pageSize = 5,
    search = search1
  ) => {
    try {
      const list = await getAllClientUsers(pageNumber, pageSize, search);
      if (list.status === 200) {
        setUserData(list?.data?.items);
      } else {
        throw new Error(list.error);
      }
    } catch (err) { }
  };

  useEffect(() => {
    fetchUserData();
  }, [search1]);
  const handleSearch = (value) => {
    SetSearch(value);
    // console.log(value, "test values");
    fetchUserData();
  };
  console.log(data, 'tvtData');
  return waitLoader ? (
    <WaitingLoader />
  ) : loading ? (
    <Loader />
  ) : (
    <div className="tvtDetail ps-4">
      <Link
        className="back-btn fw-500 text-black pt-4 ms-0"
        onClick={() => navigate(-1)}
      >
        <i className="pe-2 align-middle">
          <FaCircleChevronLeft />
        </i>
        Back
      </Link>
      <div className="dashboard-centerd">
        <div className="row g-0">
          <div className="col-xl-12 col-xxl-12 pe-4 pt-4 border-end center-height pb-5">
            <div className="row mb-5">
              <div className="col-lg-6 sideImage mb-lg-0 mb-4">
                <figure
                  className="mb-0 imageFigure"
                  style={{
                    background: `url(${data?.image})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  {/* <img src={data?.image} /> */}
                </figure>
              </div>
              <div className="col-lg-6 sideText">
                <div className="mainText mb-3">
                  <h4 style={{ color: "#000" }}>{data?.series}</h4>
                  <h6 className="fw-bold" style={{ color: "green" }}>
                    {data?.tradePrice} RED{" "}
                    {`($${(data?.tradePrice * conversionRate)
                      .toString()
                      .match(/^-?\d+(?:.\d{0,2})?/)?.[0]
                      })`}
                  </h6>
                </div>
                {card == "buy" && data?.myHolding > 0 && (
                  <div className="p-2" style={{ border: "1px solid" }}>
                    <table className="w-100">
                      <tr>
                        <th>My Holding</th>
                        <th>Value in RED</th>
                        <th>Value in USDC</th>
                      </tr>
                      <tr>
                        <td>{Number(data?.myHolding).toLocaleString()}</td>
                        <td>
                          {Number(
                            data?.myHolding * data?.tradePrice
                          ).toLocaleString()}
                        </td>
                        <td>
                          {Number(
                            Number(
                              data?.myHolding *
                              data?.tradePrice *
                              conversionRate
                            )
                              .toString()
                              .match(/^-?\d+(?:.\d{0,2})?/)?.[0]
                          ).toLocaleString()}
                        </td>
                      </tr>
                    </table>
                  </div>
                )}
                <div className="descText mt-2">
                  <h6 style={{ color: "#000" }}>Description</h6>
                  <p>{data?.description}</p>
                </div>
                <div className="descText mt-2">
                  <h6 style={{ color: "#000" }}>Tvt Contract Address :</h6>
                  <p>{data?.contractAddress}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 collectionDetails">
                <div className="p-3 mintNowText">
                  <h6 style={{ color: "#000" }} className="mb-3">
                    {card == "sell"
                      ? "Sell your TVT now on user-marketplace"
                      : "Buy now to get your TVT"}
                  </h6>
                  <h6 style={{ color: "#000" }} className="mb-3">
                    {data?.tradePrice} RED / {data?.symbol}
                  </h6>
                  {card == "sell" && (
                    <h6 style={{ color: "#000" }} className="mb-3">
                      My Holding :{" "}
                      <span className="text-success">
                        {" "}
                        {Number(data?.balance).toLocaleString()}{" "}
                        {data?.balance && data?.symbol}
                      </span>{" "}
                      <span>
                        {" "}
                        (
                        {Number(
                          data?.balance * data?.tradePrice
                        ).toLocaleString()}
                        RED)
                      </span>
                    </h6>
                  )}{" "}
                  {card == "sell" ? (
                    <>
                      {
                        `${data?.contractAddress}`.toLowerCase() != `${booqNAddress}`.toLowerCase()
                          // data?.isNewTvt
                          ?
                          (<div className="mintButton">
                            <button className="btn" onClick={() => onSell()}>
                              Sell on User-Marketplace
                            </button>
                            <button className="btn mx-2" onClick={() => onSend()}>
                              Send
                            </button>
                          </div>)
                          :
                          // null
                          (<div className="mintButton">
                            <button className="btn" onClick={() => updateTvtFunction()}>
                              Upgrade your TVT
                            </button>
                          </div>)
                      }
                    </>
                  ) : (
                    <div className="mintButton">
                      <button
                        className="btn"
                        onClick={() => {
                          if (userDataContext?.maintenanceMode) {
                            maintenancemodePopup();
                          } else {
                            buy(data);
                          }
                        }}
                      >
                        Buy Now
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header>
          <h5 className="" style={{ margin: "auto" }}>
            {" "}
            Sell TVT on user marketplace
          </h5>
          <span
            style={{ cursor: "pointer" }}
            className="d-flex justify-content-end mb-2"
            onClick={handleClose}
          >
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                price: data?.tradePrice,
                Quantity: "",
              }}
              validationSchema={Schema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ errors, touched, values, setFieldValue, setTouched }) => (
                <Form className="sellNFT-form">
                  <div className="row align-items-start justify-content-center">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label htmlFor="propertyName">Quantity:</label>
                          <Field
                            type="number"
                            name="quantity"
                            id="quantity"
                            placeholder="Enter Quantity"
                            className="form-control"
                          />
                          {errors.quantity && touched.quantity ? (
                            <div className="input-error">{errors.quantity}</div>
                          ) : null}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label htmlFor="propertyName">
                            Price (RED) / TVT:
                          </label>
                          <Field
                            type="number"
                            name="price"
                            id="price"
                            placeholder="Enter Price"
                            className="form-control"
                          />
                          {errors.price && touched.price ? (
                            <div className="input-error">{errors.price}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <Button
                        className="w-100 common-btn1"
                        variant="primary"
                        type="submit"
                      >
                        Sell
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openBuy}
        onHide={handleBuyClose}
        animation={false}
      >
        <Modal.Header>
          <h5 className="" style={{ margin: "auto" }}>
            {" "}
            Buy TVT
          </h5>
          <span
            style={{ cursor: "pointer" }}
            className=" d-flex justify-content-end mb-2"
            onClick={handleBuyClose}
          >
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                quantity: "",
              }}
              validationSchema={BuySchema}
              onSubmit={onBuy}
              enableReinitialize
            >
              {({ errors, touched, values, setFieldValue, setTouched }) => (
                <Form className="sellNFT-form">
                  <div className="row align-items-start justify-content-center">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="mb-2" htmlFor="propertyName">
                            Quantity:
                          </label>
                          <Field
                            name="quantity"
                            id="quantity"
                            placeholder="Enter Quantity"
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("quantity", e.target.value);
                              setTotalRedAmount(
                                e.target.value * data?.tradePrice
                              );
                            }}
                          />
                          {errors.quantity && touched.quantity ? (
                            <div className="input-error">{errors.quantity}</div>
                          ) : null}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="mb-2" htmlFor="propertyName">
                            Price (In RED):
                          </label>
                          <Field
                            value={totalRedAmount}
                            name="priceinred"
                            id="priceinred"
                            placeholder="Enter Price in RED"
                            className="form-control"
                            disabled
                          />
                          {errors.priceinred && touched.priceinred ? (
                            <div className="input-error">
                              {errors.priceinred}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <Button
                        className="w-100 common-btn1"
                        variant="primary"
                        type="submit"
                      >
                        Buy
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showSend}
        onHide={handleCloseSend}
        animation={false}
      >
        <Modal.Header>
          <h5 className="" style={{ margin: "auto" }}>
            {" "}
            Send TVT
          </h5>
          <span
            style={{ cursor: "pointer" }}
            className="d-flex justify-content-end mb-2"
            onClick={handleCloseSend}
          >
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="platform"
            id="sendred_tabs"
            className="marketplace-tabs border-0 mb-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Tab eventKey="platform" title="Solos Platform">
              <div>
                <Formik
                  initialValues={{
                    userName: "",
                    walletAddress: "",
                    quantity: "",
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={(values, actions) => {
                    setTimeout(() => {
                      onFormSubmit(values, actions);
                      actions.setSubmitting(false);
                    }, 500);
                  }}
                >
                  {({ handleSubmit, errors, touched, setFieldValue }) => (
                    <Form onSubmit={handleSubmit} className="row user-form">
                      <div className="col-md-12 mb-3">
                        <label>Enter User Name</label>
                        <Field
                          onChange={(e) => {
                            setFieldValue("userName", e.target.value);
                            handleSearch(e.target.value);
                          }}
                          autoComplete="off"
                          type="text"
                          placeholder="Enter User Name"
                          name="userName"
                          className="form-ctl"
                        />
                        <h6 className="text-end mb-0 mt-1">{userFullName}</h6>
                        {search1 == "" ? (
                          ""
                        ) : (
                          <div className="customSearch">
                            {userData.length == 0 ? (
                              <div> No Data Found. </div>
                            ) : (
                              userData?.map((item, i) => (
                                <div
                                  onClick={() => {
                                    setFieldValue(
                                      "walletAddress",
                                      item?.walletAddress
                                    );
                                    setFieldValue("userName", item?.userName);
                                    SetUserFullName(
                                      item?.name + " " + item?.lastname
                                    );
                                    SetSearch("");
                                  }}
                                  className="items"
                                  key={i}
                                >
                                  <div
                                    style={{
                                      padding: "5px 0px",
                                      borderBottom: "1px solid #2c29294d",
                                    }}
                                  >
                                    {item?.userName} {" - "}
                                    {item?.name + " " + item?.lastname}
                                  </div>
                                </div>
                              ))
                            )}
                          </div>
                        )}
                        {errors.userName && touched.userName ? (
                          <div className="text-danger">{errors.userName}</div>
                        ) : null}
                      </div>
                      <div className="col-md-12 mb-3">
                        <label>Enter Wallet Address</label>
                        <Field
                          type="text"
                          placeholder="Enter Wallet Address"
                          name="walletAddress"
                          className="form-ctl"
                        />
                        {errors.walletAddress && touched.walletAddress ? (
                          <div className="text-danger">{errors.walletAddress}</div>
                        ) : null}
                      </div>

                      <div className="col-md-12 mb-3">
                        <label>Enter Quantity</label>
                        <Field
                          type="text"
                          placeholder="Enter quantity"
                          name="quantity"
                          className="form-ctl"
                        />
                        {errors.quantity && touched.quantity ? (
                          <div className="text-danger">{errors.quantity}</div>
                        ) : null}
                      </div>

                      <div className="col-12">
                        {loader ? (
                          <Button
                            // disabled={loader}
                            className="w-100 common-btn"
                            variant="primary"
                          >
                            <span className="typeWriter">
                              processing<span> . . . . .</span>
                            </span>
                          </Button>
                        ) : (
                          <Button
                            // disabled={loader}
                            className="w-100 common-btn"
                            variant="primary"
                            type="submit"
                          >
                            Send
                          </Button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Tab>
            <Tab eventKey="outsideplatform" title="Outside Solos">
              <div>
                <Formik
                  initialValues={{
                    walletAddress: "",
                    quantity: "",
                  }}
                  validationSchema={SendOutsideSchema}
                  onSubmit={(values, actions) => {
                    setTimeout(() => {
                      onFormSubmit(values, actions);
                      actions.setSubmitting(false);
                    }, 500);
                  }}
                >
                  {({ handleSubmit, errors, touched }) => (
                    <Form onSubmit={handleSubmit} className="row user-form">
                      <div className="col-md-12 mb-3">
                        <label>Enter Wallet Address</label>
                        <Field
                          type="text"
                          placeholder="Enter Wallet Address"
                          name="walletAddress"
                          className="form-ctl"
                        />
                        {errors.walletAddress && touched.walletAddress ? (
                          <div className="text-danger">{errors.walletAddress}</div>
                        ) : null}
                      </div>

                      <div className="col-md-12 mb-3">
                        <label>Enter Quantity</label>
                        <Field
                          type="text"
                          placeholder="Enter quantity"
                          name="quantity"
                          className="form-ctl"
                        />
                        {errors.quantity && touched.quantity ? (
                          <div className="text-danger">{errors.quantity}</div>
                        ) : null}
                      </div>

                      <div className="col-12">
                        {loader ? (
                          <Button
                            // disabled={loader}
                            className="w-100 common-btn"
                            variant="primary"
                          >
                            <span className="typeWriter">
                              processing<span> . . . . .</span>
                            </span>
                          </Button>
                        ) : (
                          <Button
                            // disabled={loader}
                            className="w-100 common-btn"
                            variant="primary"
                            type="submit"
                          >
                            Send
                          </Button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
      <PinConfirm
        setShow={setOpenBuy}
        formData={formData}
        showPinModal={showPinModal}
        handleSubmit={onBuyFunction}
        handleClosePinModal={handleClosePinModal}
      />
      <PinConfirm2
        setShow={setShow}
        formData={formData2}
        showPinModal={showPinModal2}
        handleSubmit={onSellFunction}
        handleClosePinModal={handleClosePinModal2}
      />
    </div>
  );
};

export default TvtDetail;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { ThirdwebProvider, embeddedWallet } from "@thirdweb-dev/react";
import { Polygon } from "@thirdweb-dev/chains";
import variables from "./Config";
import Conversions from "./Context/context";

function getLibrary(provider) {
  return new Web3Provider(provider);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>

    <ThirdwebProvider
      activeChain={Polygon}
      autoConnect={true}
      supportedChains={[
        Polygon
      ]}
      supportedWallets={[
        embeddedWallet({})
      ]}
      sdkOptions={{
        gasSettings: {
          maxPriceInGwei: 300, // Maximum gas price for transactions (default 300 gwei)
          speed: "fastest", // the tx speed setting: 'standard'|'fast|'fastest' (default: 'fastest')
        },
        gasless: {
          engine: {
            // Here we specify our engine relayer URL that we created earlier
            relayerUrl: "https://solosfi.engine-usw2.thirdweb.com/relayer/ff90ca47-16ac-4462-9a01-ac3448a8ceff",
          },
        },
      }}
      clientId={variables.thirdweb_client_id}
    >
      <Conversions>
        <Router>
          <Web3ReactProvider getLibrary={getLibrary}>
            <App />
          </Web3ReactProvider>
          ,
        </Router>
      </Conversions>
      <ToastContainer />
    </ThirdwebProvider>
  </React.StrictMode>
);

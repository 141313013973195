import {
  get,
  getUserInfoAxios,
  post,
  postAsForm,
  put,
  putAsForm,
} from "../helpers/api_helper";
import appendData from "../utils/formData";

export const getallProperties = (id) => {
  return get(`/client/leasePropertyYouWant?propertyId=${id}`);
};
export const getConversionRate = () => {
  return get("/client/buyRedToken");
};
export const getMyInfoAxios = (token) => {
  return getUserInfoAxios("/client/me", { headers: { Authorization: token } });
};
export const getMyInfo = () => {
  return get("/client/me");
};
export const getMyAssetBalance = () => {
  return get("/client/totalAssets");
};
export const requestRedToken = (data) => {
  return post("/client/requestRedToken", data);
};
export const walletData = () => {
  return get("/client/walletData");
};
export const postLeaseRequest = (data) => {
  const formData = appendData(data);
  return postAsForm("/client/leaseRequest", formData);
};
export const getLeaseRequest = (page) => {
  return get(`/client/leasePropertyRequestData?pageNumber=${page}&pageSize=8`);
};
export const getRedTrasactions = (page, activeTab) => {
  return get(
    `/client/redTokenTransactionsDetails?pageNumber=${page}&pageSize=8&transactionType=${activeTab}`
  );
};
export const getTokenTrasactions = (page, pageSize, activeTab, marketType) => {
  return get(
    `/client/tokenTransactionsDetails?pageNumber=${page}&pageSize=${pageSize}&transactionType=${activeTab}&marketType=${marketType}`
  );
};
export const getSrTrasactions = (page) => {
  return get(`/client/getSolosRewardTransaction?pageNumber=${page}&pageSize=8`);
};
export const Sendsr = (data) => {
  return post("/client/sendSolosRewardByUser", data);
};
export const SendRed = (data) => {
  return post("/client/sendRedToken", data);
};
export const transaction = (data) => {
  return post("/transaction/saveTransactionOfRedAndSoloReward", data);
};
export const saveSendTransaction = (data) => {
  return post("/transaction/saveSendTransaction", data);
};
export const UpdateProfile = (data) => {
  const formData = appendData(data);
  return putAsForm("/client/updateProfile", formData);
};

export const getPossession = (page) => {
  return get(`/client/possesionListing?pageNumber=${page ?? 1}&pageSize=9`);
};
export const getLeaseTransaction = (page) => {
  return get(
    `/transaction/getLeaseTransactionsDetails?pageNumber=${page}&pageSize=12`
  );
};
export const getAllClientUsers = (pageNumber, pageSize, search) => {
  return get(
    `admin/getAllUsers?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}&filterFor=referral&sortOrder=asc`
  );
};
export const getRedPriceInUsd = (page) => {
  return get(`/admin/getFredExchangeRedTokenPercentage`);
};
export const getSrPriceInUsd = (page) => {
  return get(`/admin/getConversionRateForSoloReward`);
};
export const terminateLease = (data) => {
  return put(`/client/terminateLease`, data);
};
export const transactionToLeaseProperty = (data) => {
  return post(`/transaction/transactionToLeaseProperty`, data);
};
export const changePassword = (data) => {
  return post(`/client/changePassword`, data);
};
export const createChangeMpin = (data) => {
  return post(`/client/createMpin`, data);
};
export const enableDisableSecurity = (data) => {
  return post("/client/enableDisableSecurity", data);
};
export const buySolosReward = (data) => {
  return post("/client/buySolosReward", data);
};
export const sellSolosTransaction = (data) => {
  return post("/client/sellSolosTransaction", data);
};
export const getSoloAssets = (walletAddress, pageNumber = 1, pageSize = 10) => {
  return get(
    `/client/getTotalSoloAssets?walletAddress=${walletAddress}&pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
};
export const postFierceTransaction = (data) => {
  return post("/client/fierceTransaction", data);
};
export const getFierceCustomer = (data) => {
  return get("/fierce/customer", data);
};
export const getFierceCustomerBalance = (data) => {
  return get("/fierce/customer-balance", data);
};
export const mapFierceCustomer = (data) => {
  return post("/fierce/customer", data);
};
export const buyRedFromFierce = (data) => {
  return post("/fierce/buyRed", data);
};
export const getAllContacts = (pageNumber, pageSize, search, tab = 'CONTACTS') => {
  return get(
    `/client/getAllContacts?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}&tab=${tab}`
  );
};
export const addContact = (data) => {
  return post("/client/addContact", data);
};

import React from "react";
import { useNavigate } from "react-router-dom";
import "./FredCard.css";

const BuyFredCard = ({ data, fetchData, setLoading }) => {

  const navigate = useNavigate();

  return (
    <>
      <div className="fredCard" style={{ height: "100%" }}>
        <div className="border rounded p-3" style={{ height: "100%" }}>
          <div
            className="d-flex align-items-center mb-3 pb-3"
            style={{
              justifyContent: "space-between",
              borderBottom: "1px solid #cecece",
            }}
          >
            <div className="d-flex align-items-center mb-2">
              <h6 className="fw-bold ms-2 mb-0">{data?.name}</h6>
            </div>
            <button className="buyButton btn btn-primary btn-sm ms-auto mt-2">
              FRED
            </button>
          </div>
          <figure
            className="tranding-img rounded position-relative text-center p-2"
            style={{
              background: `url(${data?.image})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "200px",
            }}
          >
            {/* <img src={data?.nftImage} /> */}
          </figure>
          <div className="card-details">
            {/* <div>
              <h6 className="fw-bold">{data?.name}</h6>
            </div> */}
            <div className="d-flex mcard-content">
              <span className="fw-500">Symbol </span>
              <span className="text-uppercase text-success ms-auto">
                {data?.symbol}
              </span>
            </div>
            <div className="d-flex mcard-content">
              <span className="fw-500">Price </span>
              <span className="text-uppercase text-success ms-auto">
                {Number(data?.tradePrice)?.toLocaleString()} RED
              </span>
            </div>
            <div className="d-flex mcard-content">
              <span className="fw-500">Quantity </span>
              <span className="text-uppercase text-success ms-auto">
                {Number(data?.balance).toLocaleString()}
              </span>
            </div>
            <div className="d-flex mcard-content">
              <span className="fw-500">My Holdings </span>
              <span className="text-uppercase text-success ms-auto">
                {Number(data?.balance)?.toLocaleString()} {data?.symbol}
              </span>
            </div>
            <div className="d-flex mcard-content">
              <span className="fw-500">Current Value </span>
              <span className="text-uppercase text-success ms-auto">
                {Number(data?.balance * data?.tradePrice)?.toLocaleString()} RED
              </span>
            </div>
          </div>

          <div className="d-flex">
            <button
              className="buyButton btn btn-primary btn-sm ms-auto mt-2"
              onClick={() =>
                navigate("/fred-details", {
                  state: { data: data, card: "sell" },
                })
              }
            >
              View Detail
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyFredCard;

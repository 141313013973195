import { Field, Formik } from 'formik';
import * as yup from "yup";
import React, { useContext, useState } from 'react'
import { fireToast, toastConfirm } from "../../common/Toster";
import { Button, Form, Modal, Tab, Tabs } from 'react-bootstrap';
import { IoCloseSharp } from 'react-icons/io5';
import { ConversionContext } from "../../Context/context";
import { ethers } from 'ethers';
import PinConfirm from "../Modals/PinConfirm";
import { useAddress, useSigner } from '@thirdweb-dev/react';
import configURl from '../../runtime.config';
import usdcAbi from "../../Contracts/usdc.json";
import {
    getAllClientUsers,
    transaction,
} from "../../services/propertyServices";
function SendUsdc({
    showSend,
    setShowSend,
    getTokenBalance
}) {
    const signer = useSigner();
    const address = useAddress();
    const [search1, setSearch] = useState("");
    const contextData = useContext(ConversionContext);
    const [amount, setAmount] = useState(0);
    const [userData, setUserData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [formData, setFormData] = useState({});
    const [showPinModal, setShowPinModal] = useState(false);
    const [userFullName, setUserFullName] = useState("");
    const SendSchema = yup.object().shape({
        walletAddress: yup
            .string()
            .min("42")
            .max("42")
            .required("Address is required"),
    });
    const isPinActive = contextData?.userData?.isMpinActive;
    const handleClosePinModal = () => {
        setShowPinModal(false);
    };
    const fetchUserData = async (
        pageNumber = 1,
        pageSize = 5,
        search = search1
    ) => {
        try {
            const list = await getAllClientUsers(pageNumber, pageSize, search);
            if (list.status === 200) {
                setUserData(list?.data?.items);
            } else {
                throw new Error(list.error);
            }
        } catch (err) { }
    };
    const handleSearch = (value) => {
        setSearch(value);
        fetchUserData();
    };

    const sendCustomAssets = async (data) => {
        try {
            setLoader(true);
            if (amount <= 0) {
                fireToast("error", "Amount must be greater than 0 !");
                return;
            }
            const amt = Number(amount).toFixed(5).toString();
            const amountToBePassed = ethers.utils.parseUnits(amt, 6);

            const maticBalance = ethers.utils.formatEther(await signer.getBalance());
            if (+maticBalance < 1) {
                fireToast("error", "Insufficient matic funds for gas price !");
                setLoader(false);
                return;
            }

            const instance = new ethers.Contract(
                configURl.usdcAddress,
                usdcAbi,
                signer
            );
            const balance = ethers.utils.formatUnits(
                await instance.balanceOf(address),
                6
            );
            if (+balance < +amt) {
                fireToast("error", "Transfer amount exceeds balance !");
                setLoader(false);
                return;
            }

            const tx = await instance.transfer(
                data?.walletAddress,
                amountToBePassed,
                {
                    gasLimit: 600000,
                }
            );
            const response = await tx;
            if (response) {
                getTokenBalance();
                setLoader(false);
                transaction({
                    walletAddress: data.walletAddress,
                    transactionType: "Usdc Token",
                    token: amount,
                    userName: data?.userName,
                    hashId: response.hash,
                }).then((res) => {
                    setShowSend(false);
                });
                fireToast("success", "Successfully Sent.");
                setShowSend(false);
            }
        } catch (err) {
            console.log(err, "sendCustomAssets error");
        } finally {
            setLoader(false);
            setShowSend(false);
        }
    };
    const onFormSubmit = async (data, actions) => {
        toastConfirm(
            "Are you sure?",
            `You want to send ${amount} USDC tokens to ${data?.userName ? data?.userName : data?.walletAddress
            }?`
        )
            .fire()
            .then(async (val) => {
                if (val.isConfirmed) {
                    (isPinActive
                        ? () => {
                            setShowSend(false);
                            setFormData(data);
                            setShowPinModal(true);
                        }
                        : () => {
                            sendCustomAssets(data);
                        })();
                    // sendCustomAssets(data);
                }
            });
    };

    return (
        <>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showSend}
                animation={false}
                onHide={() => {
                    setSearch("")
                    setUserData([])
                    setShowSend(false)
                    setUserFullName("");
                }}
            >
                <Modal.Body>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <span
                                className="back-btn fw-500 text-black"
                            >
                                Send USDC
                            </span>
                        </div>
                        <div>
                            <span
                                onClick={() => {
                                    setSearch("")
                                    setUserData([])                
                                    setShowSend(false);
                                    setUserFullName("");
                                }}
                                style={{ cursor: "pointer", fontSize: "22px" }}
                            >
                                <IoCloseSharp />
                            </span>
                        </div>
                    </div>

                    <div className="dashboard-centerd">
                        <div className="row g-0">
                            <div className="col-xl-12 col-xxl-12  pt-4  pb-5">
                                <div className="">
                                    <Tabs
                                        defaultActiveKey="platform"
                                        id="sendusdc_tabs"
                                        className="marketplace-tabs border-0 mb-4"
                                        style={{ display: "flex", justifyContent: "center" }}
                                    >
                                        <Tab eventKey="platform" title="Solos Platform">
                                            <div className="px-2 my-4">
                                                <Formik
                                                    initialValues={{
                                                        userName: "",
                                                        walletAddress: "",
                                                        accountType: "Primary",
                                                    }}
                                                    validationSchema={SendSchema}
                                                    onSubmit={(values, actions) => {
                                                        setTimeout(() => {
                                                            onFormSubmit(values, actions);
                                                            actions.setSubmitting(false);
                                                        }, 500);
                                                    }}
                                                >
                                                    {({
                                                        handleSubmit,
                                                        errors,
                                                        touched,
                                                        setFieldValue,
                                                    }) => (
                                                        <Form
                                                            onSubmit={handleSubmit}
                                                            className="row user-form"
                                                        >
                                                            <Form.Group
                                                                className="col-12 mb-3"
                                                                controlId="formBasicEmail"
                                                            >
                                                                <Form.Label>Enter User Name</Form.Label>
                                                                <Field
                                                                    onChange={(e) => {
                                                                        setFieldValue("userName", e.target.value);
                                                                        handleSearch(e.target.value);
                                                                    }}
                                                                    autoComplete="off"
                                                                    type="text"
                                                                    placeholder="Enter User Name"
                                                                    name="userName"
                                                                    className="form-ctl"
                                                                />
                                                                <h6 className="text-end mb-0 mt-1">
                                                                    {userFullName}
                                                                </h6>
                                                                {search1 == "" ? (
                                                                    ""
                                                                ) : (
                                                                    <div className="customSearch">
                                                                        {userData.length == 0 ? (
                                                                            <div> No Data Found. </div>
                                                                        ) : (
                                                                            userData?.map((item, i) => (
                                                                                <div
                                                                                    onClick={() => {
                                                                                        setFieldValue(
                                                                                            "walletAddress",
                                                                                            item?.walletAddress
                                                                                        );
                                                                                        setFieldValue(
                                                                                            "userName",
                                                                                            item?.userName
                                                                                        );
                                                                                        setUserFullName(
                                                                                            item?.name + " " + item?.lastname
                                                                                        );
                                                                                        setSearch("");
                                                                                    }}
                                                                                    className="items"
                                                                                    key={i}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            padding: "5px 5px",
                                                                                            border: "1px solid #2c29294d",
                                                                                        }}
                                                                                    >
                                                                                        {item?.userName}
                                                                                        {" - "}
                                                                                        {item?.name + " " + item?.lastname}
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        )}
                                                                    </div>
                                                                )}
                                                                {errors.userName && touched.userName ? (
                                                                    <div className="text-danger">
                                                                        {errors.userName}
                                                                    </div>
                                                                ) : null}
                                                            </Form.Group>
                                                            <Form.Group className="col-12 mb-4" controlId="">
                                                                <Form.Label>Enter Wallet Address</Form.Label>
                                                                <Field
                                                                    type="text"
                                                                    placeholder="Enter Wallet Address"
                                                                    name="walletAddress"
                                                                    className="form-ctl"
                                                                />
                                                                {errors.walletAddress &&
                                                                    touched.walletAddress ? (
                                                                    <div className="text-danger">
                                                                        {errors.walletAddress}
                                                                    </div>
                                                                ) : null}
                                                            </Form.Group>

                                                            <Form.Group className="col-12 mb-4" controlId="">
                                                                <Form.Label>Enter USDC Amount</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Enter amount"
                                                                    className="form-ctl"
                                                                    value={amount}
                                                                    onChange={(e) => setAmount(e.target.value)}
                                                                />
                                                            </Form.Group>

                                                            <div className="col-12">
                                                                {loader ? (
                                                                    <Button
                                                                        // disabled={loader}
                                                                        className="w-100 common-btn"
                                                                        variant="primary"
                                                                    >
                                                                        <span className="typeWriter">
                                                                            processing<span> . . . . .</span>
                                                                        </span>
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        // disabled={loader}
                                                                        className="w-100 common-btn"
                                                                        variant="primary"
                                                                        type="submit"
                                                                    >
                                                                        Send
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="outsideplatform" title="Outside Solos">
                                            <div className="px-2 my-4">
                                                <Formik
                                                    initialValues={{
                                                        userName: "",
                                                        walletAddress: "",
                                                        // redToken: "",
                                                        accountType: "Primary",
                                                    }}
                                                    validationSchema={SendSchema}
                                                    onSubmit={(values, actions) => {
                                                        setTimeout(() => {
                                                            onFormSubmit(values, actions);
                                                            actions.setSubmitting(false);
                                                        }, 500);
                                                    }}
                                                >
                                                    {({
                                                        handleSubmit,
                                                        errors,
                                                        touched,
                                                        setFieldValue,
                                                    }) => (
                                                        <Form
                                                            onSubmit={handleSubmit}
                                                            className="row user-form"
                                                        >
                                                            <Form.Group className="col-12 mb-4" controlId="">
                                                                <Form.Label>Enter Wallet Address</Form.Label>
                                                                <Field
                                                                    type="text"
                                                                    placeholder="Enter Wallet Address"
                                                                    name="walletAddress"
                                                                    className="form-ctl"
                                                                />
                                                                {errors.walletAddress &&
                                                                    touched.walletAddress ? (
                                                                    <div className="text-danger">
                                                                        {errors.walletAddress}
                                                                    </div>
                                                                ) : null}
                                                            </Form.Group>

                                                            <Form.Group className="col-12 mb-4" controlId="">
                                                                <Form.Label>Enter USDC Amount</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Enter amount"
                                                                    className="form-ctl"
                                                                    value={amount}
                                                                    onChange={(e) => setAmount(e.target.value)}
                                                                />
                                                            </Form.Group>

                                                            <div className="col-12">
                                                                {loader ? (
                                                                    <Button
                                                                        // disabled={loader}
                                                                        className="w-100 common-btn"
                                                                        variant="primary"
                                                                    >
                                                                        <span className="typeWriter">
                                                                            processing<span> . . . . .</span>
                                                                        </span>
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        // disabled={loader}
                                                                        className="w-100 common-btn"
                                                                        variant="primary"
                                                                        type="submit"
                                                                    >
                                                                        Send
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <PinConfirm
                setShow={setShowSend}
                formData={formData}
                showPinModal={showPinModal}
                handleSubmit={sendCustomAssets}
                handleClosePinModal={handleClosePinModal}
            />

        </>

    )
}

export default SendUsdc

let modeType = window.location.hostname.includes("localhost")
  ? "Live"
  : window.location.hostname.includes("dev")
    ? "Dev"
    : "Live";

let configURl = {};
if (modeType == "Dev") {
  configURl = {
    BaseURL: "https://dev-apis.solosfi.com/api",
    BaseURLImg: "https://dev-apis.solosfi.com/uploads/",
    thirdweb_client_id: "49432823dee0f092f9b65deee415ea33",
    thirdweb_secret_key:
      "qYvcLFg0F6DMud7dwem1yDS1LUMbECFALHu6-hA65S4X13PROjrngIkXFLMjXyFqQV1fDcKq-AKRjBmM31nQKA",
  };
} else {
  configURl = {
    BaseURL: "https://apis.solosfi.com/api",
    BaseURLImg: "https://apis.solosfi.com/uploads/",
    thirdweb_client_id: "bd912c66608ab55c2b0fc89f4a2ad5e7",
    thirdweb_secret_key:
      "hgM0HMV6tOjXbHRwIqdnH1FSxZnefW2ORen5diAiqlJN8XhenX3cnMS197SLo8G62TotKZh7jWXwGOKxJA9ZSw",
  };
}

configURl.bridgeContractAddress = "0x565D55f0ECcaf1b23214B8819B481DdC5d7bFea2";
configURl.adminWalletAddress = "0x42E72b3Bb3a32408D42948C3eFb32B4A56828C8f";
configURl.usdcAddress = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";
configURl.redNewAddress = "0xAeE3C92D4FCFd25D88AE2465A9736B00884319c6";
configURl.NewsolosAddress = "0xB7cDFAACeA7714242123b88F97b960f7ebb1dAf8";
configURl.SR_Address = "0x310cB7B1700947F25f75080b138E65aAf0729a75";
configURl.minMaticLimit = 0.3;
configURl.polygonMainnetUrl =
  "https://polygon-mainnet.g.alchemy.com/v2/rvmpmREwuUjwDRUj3SBmkP6EDR0rSlVo";
configURl.polygonTestnetUrl =
  "https://polygon-mumbai.g.alchemy.com/v2/qRpA5Y4Fbeip7pho1zuwxPLWGdbYEhbH";

export default configURl;

import React from "react";
import { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import solosLoader from "../../images/solosLoader.gif";
import "./loader.css";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#0052ff",
};
const WaitingLoader = () => {
  return (
    <div className="loader-page">
      <div className="text-center">
        <img src={solosLoader} style={{ width: "100px", height: "100px" }} />
        {/* <ClipLoader
          cssOverride={override}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        /> */}
        <div className="mt-3" style={{ padding: "14px" }}>
          <h5 className="text-center"> Please Wait... </h5>
          <p> Do not refresh or close your browser!! </p>
        </div>
      </div>
    </div>
  );
};

export default WaitingLoader;

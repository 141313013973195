import React, { useContext, useState } from "react";
import { TbRefresh } from "react-icons/tb";
import Fierce from "../../images/fierce.png";
import './FierceCard.css';
import Dummy from "../../images/userDummy2.png";
import { Modal } from "react-bootstrap";
import MappingStatus from "./mappingStatus.json";
import { ConversionContext } from "../../Context/context.js";
import { mapFierceCustomer } from "../../services/propertyServices.js";
const FierceCard = () => {
  const [smShow, setSmShow] = useState(false);
  const { fierceLoader, setFierceLoader, userData, getCustomerBalance, setSolosId, fierceId, setFierceId, availableBalance, mappingStatus, setMappingStatus } = useContext(ConversionContext);
  const postCustomer = async (e) => {
    e.preventDefault();
    setSmShow(false);
    setFierceLoader(true);
    mapFierceCustomer()
      .then((response) => {
        console.clear();
        console.table([{ method: 'post', url: '/fierce/customer', response: response?.data }]);
        setFierceLoader(false);
        setSolosId(response.data?.solosId);
        setFierceId(response.data?.fierceId);
        setMappingStatus(response.data?.mappingStatus);
      }).catch((error) => {
        setFierceLoader(false);
        console.log(error);
      })
  }


  return (
    <>
      <Modal
        className="terms-modal"
        centered
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="header-bg" closeButton>
          CHOOSE AN ACCOUNT
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={postCustomer}>
            <div className="info-box px-2">
              <div className="d-flex align-items-center mb-3">
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    border: "1px solid gray",
                  }}
                  src={userData?.profileImage == null ? Dummy : userData?.profileImage}
                  alt=""
                />
                <div className="ps-3">
                  <h3 className="f-16 mb-0 info-username">
                    {userData?.name} {userData?.lastname && userData?.lastname}
                  </h3>
                  <small>{userData?.email}</small>
                </div>
              </div>
            </div>
            <ul className="mt-4 px-2 fs-6">
              <li>
                <label className="ms-1"><input required className="me-1 large-checkbox" type={'checkbox'}></input> I here by authorized solos to get required information from my fierce account.</label>
              </li>
              <li className="mt-3">
                <label className="ms-1"><input required className="me-1 large-checkbox" type={'checkbox'}></input> I agree to all terms and conditions.</label>
              </li>
            </ul>
            <div className="text-end mt-5">
              <button type="button" className="btn btn-outline-green" onClick={() => {
                setSmShow(false)
              }}> Cancel </button>
              <button type="submit" className="btn btn-green ms-2"> Connect </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div className="fierce-card d-flex justify-content-between flex-column position-relative">
        <div className="d-flex align-items-center justify-content-center">
          <img className="" width={90} src={Fierce} alt="" />
        </div>
        {!fierceId && <div>
          <h4 className="text-center text-white" style={{fontSize:'medium'}}> Welcome Back </h4>
        </div>}
        {
          fierceLoader ?
            <div className="text-center">
              <span>Loading...</span>
            </div>
            : <>
              {
                mappingStatus && mappingStatus !== 'NOT_MAPPED' ?
                  <>
                    {
                      ['CANNOT_BE_MAPPED', 'MAPPED_KYC_DENIED', 'MAPPED_KYC_IN_PROGRESS'].includes(mappingStatus) ?
                        <div className="text-center">
                          <span>{MappingStatus[mappingStatus]}</span>
                        </div>
                        :
                        <>
                          <div>
                            <h6>
                              <span className="text-white fw-400">USD Balance : </span>
                              <span className="text-white text-start ps-1 scard-value scard-title1">
                                $
                                {availableBalance
                                  ? Number(
                                    Number(+availableBalance)
                                      .toString()
                                      .match(/^-?\d+(?:.\d{0,2})?/)[0]
                                  ).toLocaleString()
                                  : 0}
                              </span>
                              {' '}
                              <TbRefresh fontSize={16} onClick={getCustomerBalance} />
                            </h6>
                          </div>
                          <div className="token-bottom">
                          </div>
                        </>
                    }
                  </>
                  :
                  <div className="text-center">
                    <button className="btn btn-outline-white" onClick={() => setSmShow(true)}> Connect Now !</button>
                  </div>
              }
            </>
        }
      </div>
    </>
  );
};

export default FierceCard;

import React from "react";
import { useNavigate } from "react-router-dom";
import "./FredCard.css";

const FredCard = ({
  data,
  fetchData,
  setLoading,
  adminReceiveWalletAddress,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="fredCard">
        <div className="border rounded p-3">
          <div
            className="d-flex align-items-center mb-3 pb-3"
            style={{
              justifyContent: "space-between",
              borderBottom: "1px solid #cecece",
            }}
          >
            <div className="d-flex align-items-center">
              <h6 className="fw-bold mb-0">{data?.name}</h6>
            </div>
            <button className="buyButton btn btn-primary btn-sm ms-auto mt-2">
              FRED
            </button>
          </div>

          <figure
            className="tranding-img rounded position-relative text-center p-2"
            style={{
              background: `url(${data?.image})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "200px",
            }}
          >
            {/* <img src={data?.nftImage} /> */}
          </figure>
          <div className="card-details">
            {/* <div>
              <h6 className="fw-bold">{data?.name}</h6>
            </div> */}
            <div className="d-flex" mcard-content>
              <span className="fw-500">Symbol </span>
              <span className="text-uppercase text-success ms-auto">
                {data?.symbol}
              </span>
            </div>
            <div className="d-flex mcard-content">
              <span className="fw-500">Price </span>
              <span className="text-uppercase text-success ms-auto">
                {Number(data?.tradePrice).toLocaleString()} RED
              </span>
            </div>
            <div className="d-flex mcard-content">
              <span className="fw-500">Quantity </span>
              <span className="text-uppercase text-success ms-auto">
                {Number(
                  data?.quantityForSellInSoloMarketPlace
                ).toLocaleString()}
              </span>
            </div>
            <div className="d-flex mcard-content">
              <span className="fw-500">My Holdings </span>
              <span className="text-uppercase text-success ms-auto">
                {data?.myHolding > 0
                  ? `${Number(data?.myHolding).toLocaleString() + " " + data?.symbol}`
                  : 0}
              </span>
            </div>
            <div className="d-flex">
              <span className="fw-500">Current Value </span>
              <span className="text-uppercase text-success ms-auto">
                {data?.myHolding > 0
                  ? `${Number(data?.myHolding * data?.tradePrice).toLocaleString()} RED`
                  : 0}
              </span>
            </div>
          </div>

          <div className="d-flex">
            <button
              className="buyButton btn btn-primary btn-sm ms-auto mt-2"
              onClick={() =>
                navigate("/fred-details", {
                  state: {
                    data: data,
                    card: "buy",
                    adminReceiveWalletAddress: adminReceiveWalletAddress,
                  },
                })
              }
            >
              View Detail
            </button>
            {/* <button
              className="buyButton btn btn-primary btn-sm ms-auto"
              onClick={() => {
                if (isKycVerified) {
                  buy(data);
                } else {
                  kycErrorPopUp();
                }
              }}
            >
              Buy Now
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FredCard;

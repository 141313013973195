import React, { useContext, useState, useEffect } from "react";
import { ConversionContext } from "../../Context/context";
import { Field, Formik } from "formik";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import { useAddress, useContract, useSigner } from "@thirdweb-dev/react";
import {
    getAllContacts,
    saveSendTransaction,
} from "../../services/propertyServices";
import { fireToast, toastConfirm } from "../../common/Toster";
import configURl from "../../runtime.config";
import { ethers } from "ethers";
import PinConfirm from "../Modals/PinConfirm";
import ERC20Abi from "../../Abis/ERC20Abi.json";
import { Capatalize } from "../../common/utility";
import './SendTab.css';
import SwapAssetsDropdown from "../../common/SwapAssetsDropdown";
import { MdOutlineContacts } from "react-icons/md";
import Contacts from "../Modals/Contacts";
import { isAddress } from "ethers/lib/utils";

function SendTab() {
    const signer = useSigner();
    const { tokenToSend, userData, fetchAssets, setShowContacts } = useContext(ConversionContext);
    const isPinActive = userData?.isMpinActive;
    const { contract, isLoading, error } = useContract(
        tokenToSend?.contractAddress
    );
    const myAddress = useAddress();
    const [usersData, setUsersData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [search, setSearch] = useState("");
    const [userFullName, setUserFullName] = useState("");
    const [showPinModal, setShowPinModal] = useState(false);
    const [formData, setFormData] = useState({});
    const [walletAddress, setWalletAddress] = useState("");
    const [userName, setUserName] = useState("");
    const [quantity, setQuantity] = useState("");
    const [amount, setAmount] = useState("");
    const address = useAddress();

    const fetchUserData = async (pageNumber = 1, pageSize = 5) => {
        try {
            const list = await getAllContacts(pageNumber, pageSize, search, 'ALL');
            if (list.status === 200) {
                setUsersData(list?.data?.items);
            } else {
                throw new Error(list.error);
            }
        } catch (err) { }
    };

    useEffect(() => {
        if (search) {
            fetchUserData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const handleSearch = (value) => {
        setSearch(value);
        setUserFullName("");
    };

    const sendRed = async (data) => {
        try {
            if (isLoading) {
                fireToast(
                    "error",
                    "Please try again after few seconds contract is initalizing !"
                );
                return;
            }
            if (error) {
                console.log(error, "contract error");
                fireToast(
                    "error",
                    "Sorry currently we are facing some issue in initalizing contract !"
                );
                return;
            }
            if (data?.quantity <= 0) {
                fireToast("error", "Amount must be greater than 0 !");
                return;
            }
            setLoader(true);
            const balance = await contract.erc20.balanceOf(address);
            if (+balance?.displayValue < data?.quantity) {
                console.log(error, "balance error");
                fireToast("error", "Transfer amount exceeds balance !");
                setLoader(false);
                return;
            }
            const tx = await contract.erc20.transfer(
                data?.walletAddress,
                data?.quantity
            );
            const response = tx.receipt;
            // console.log(response);
            if (response.status) {
                setLoader(false);
                saveSendTransaction({
                    walletAddressFrom: address,
                    contractAddress: tokenToSend?.contractAddress,
                    assetType: tokenToSend?.assetType,
                    walletAddressTo: data.walletAddress,
                    transactionType: `${Capatalize(tokenToSend?.symbol)} Token`,
                    quantity: data.quantity,
                    userName: data?.userName ? data?.userName : "",
                    hashId: response.transactionHash,
                }).then((res) => {
                    console.log(res, "res");
                });
                fetchAssets();
                fireToast("success", "Successfully Sent.");
            }
        } catch (err) {
            fireToast("error", "Something went wrong please try again later !");
            console.log(err, "sendCustomAssets error");
        } finally {
            setLoader(false);
        }
    };

    const sendOtherAssets = async (data) => {
        try {
            if (data?.quantity <= 0) {
                fireToast("error", "Amount must be greater than 0 !");
                return;
            }
            setLoader(true);
            const contractInstance = new ethers.Contract(
                tokenToSend?.contractAddress,
                ERC20Abi,
                signer
            );
            const maticBalance = ethers.utils.formatEther(await signer.getBalance());
            const gasPrice = ethers.utils.parseUnits("500", "gwei");
            if (+maticBalance < configURl.minMaticLimit) {
                fireToast("error", "Insufficient matic funds for gas price !");
                setLoader(false);
                return;
            }
            const decimals = await contractInstance.decimals();
            const balance = await contractInstance.balanceOf(myAddress);
            const formatSrBalance = ethers.utils.formatUnits(balance, decimals);
            if (+formatSrBalance < +data?.quantity) {
                fireToast("error", "Balance Is Low !");
                setLoader(false);
                return;
            }
            const hexQuantity = ethers.utils.parseUnits(
                data?.quantity.toString(),
                decimals
            );
            const response = await contractInstance.transfer(
                data?.walletAddress,
                hexQuantity,
                {
                    maxFeePerGas: gasPrice,
                    maxPriorityFeePerGas: gasPrice,
                    gasLimit: 600000,
                }
            );
            if (response?.hash) {
                setLoader(false);
                saveSendTransaction({
                    walletAddressFrom: address,
                    assetId: tokenToSend?._id,
                    amount: "0",
                    contractAddress: tokenToSend?.contractAddress,
                    assetType: tokenToSend?.assetType,
                    walletAddressTo: data.walletAddress,
                    transactionType:
                        tokenToSend?.symbol === "SR"
                            ? data?.categories
                            : `${Capatalize(tokenToSend?.symbol)} Token`,
                    quantity: data.quantity,
                    userName: data?.userName ? data?.userName : "",
                    hashId: response.hash,
                }).then((res) => {
                    console.log(res, "res");
                });
                setFormData({});
                fetchAssets();
                fireToast("success", "Successfully Sent.");
            }
        } catch (err) {
            fireToast("error", "Something went wrong please try again later !");
            console.log(err, "sendCustomAssets error");
        } finally {
            setLoader(false);
        }
    };
    const sendCustomAssets = async (data) => {
        if (tokenToSend?.symbol === "RED") {
            await sendRed(data);
        } else {
            await sendOtherAssets(data);
        }
    };
    const onFormSubmit = async (data, actions) => {
        toastConfirm(
            "Are you sure?",
            `You want to send ${data.quantity} ${tokenToSend?.symbol} tokens to ${data?.walletAddress}?`
        )
            .fire()
            .then(async (val) => {
                if (val.isConfirmed) {
                    if (isPinActive) {
                        setFormData(data);
                        setShowPinModal(true);
                    } else {
                        sendCustomAssets(data);
                    }
                }
            });
    };
    const SendSchema = Yup.object().shape({
        quantity: Yup.number()
            .required(`Amount is required`)
            .moreThan(0, "Amount must be greater than 0"),
        walletAddress: Yup.string()
            .test("isValid", "Wallet Address is not valid", value => isAddress(value))
            .required("Wallet Address is required"),
    });
    useEffect(()=>{
        setAmount("");
        setQuantity("");
    },[tokenToSend])
    return (
        <div className="buy">
            <PinConfirm
                setShow={() => { }}
                formData={formData}
                showPinModal={showPinModal}
                handleSubmit={sendCustomAssets}
                handleClosePinModal={() => {
                    setShowPinModal(false);
                }}
            />

            <Formik
                enableReinitialize
                initialValues={{
                    quantity: quantity,
                    amount: amount,
                    userName: userName,
                    walletAddress: walletAddress,
                }}
                validationSchema={SendSchema}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        onFormSubmit(values, actions);
                        actions.setSubmitting(false);
                    }, 500);
                }}
            >
                {({ handleSubmit, errors, touched, values, setFieldValue }) => (
                    <Form onSubmit={handleSubmit} className="row user-form-send">
                        <div className="my-2">
                            <h6>Select Asset</h6>
                            <SwapAssetsDropdown />
                        </div>
                        <div className="my-2">
                            <h6>Enter Amount (In {`${tokenToSend?.symbol}`.toUpperCase()})</h6>
                            <Form.Group className="col-12 mb-3" controlId="formBasicEmail">
                                <Field
                                    disabled={loader}
                                    type="number"
                                    name="quantity"
                                    placeholder="0"
                                    className="form-control fs-18"
                                    onChange={(e) => {
                                        setQuantity(e.target.value);
                                        setAmount(+(e.target.value * (tokenToSend?.conversionrate || 1)).toFixed(4));
                                        setFieldValue("quantity", e.target.value);
                                        setFieldValue("amount", +(e.target.value * (tokenToSend?.conversionrate || 1)).toFixed(4));
                                    }}
                                />
                                {
                                    touched.quantity ? (
                                        <div className="text-danger fw-normal fs-12">
                                            {errors?.quantity}
                                        </div>
                                    ) : null
                                }
                            </Form.Group>
                        </div>
                        <div className="my-2">
                            <h6>Enter Amount (In USD)</h6>
                            <Form.Group className="col-12 mb-3" controlId="formBasicEmail">
                                <Field
                                    disabled={loader}
                                    type="number"
                                    name="amount"
                                    placeholder="0"
                                    className="form-control fs-18"
                                    onChange={(e) => {
                                        setQuantity(+(e.target.value / (tokenToSend?.conversionrate || 1)).toFixed(4));
                                        setAmount(e.target.value);
                                        setFieldValue("quantity", +(e.target.value / (tokenToSend?.conversionrate || 1)).toFixed(4));
                                        setFieldValue("amount", e.target.value);
                                    }}
                                />
                                {
                                    touched.amount ? (
                                        <div className="text-danger fw-normal fs-12">
                                            {errors?.amount}
                                        </div>
                                    ) : null
                                }
                            </Form.Group>
                        </div>
                        <div className="my-2">
                            <h6>Receiptent </h6>
                            <Form.Group className="col-12 mb-3" controlId="formBasicEmail">
                                <Field
                                    disabled={loader}
                                    type="text"
                                    placeholder="Enter wallet address"
                                    name="userName"
                                    onChange={(e) => {
                                        setUserName(e.target.value);
                                    }}
                                    className="form-control d-none"
                                />
                                <div className="position-relative">
                                    <Field
                                        onChange={(e) => {
                                            setFieldValue("walletAddress", e.target.value);
                                            handleSearch(e.target.value);
                                        }}
                                        autoComplete="off"
                                        type="text"
                                        placeholder="Enter username or paste address"
                                        name="walletAddress"
                                        className="form-control"
                                    />
                                    <i
                                        className="edit-icon"
                                        onClick={() => setShowContacts(true)}
                                    >
                                        <MdOutlineContacts fontSize={24} color="black" />
                                    </i>
                                </div>
                                <h6 className="text-end mb-0 mt-1">
                                    {userFullName || 'outside'}
                                </h6>
                                {
                                    search && <div className="mx-1">
                                        {
                                            usersData?.map((item, i) => (
                                                <div
                                                    key={i}
                                                    onClick={() => {
                                                        setWalletAddress(item?.walletAddress);
                                                        setUserName(item?.userName);
                                                        setUserFullName(item?.userFullName);
                                                        setSearch("");
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            padding: "5px 0px",
                                                            borderBottom:
                                                                "1px solid #2c29294d",
                                                        }}
                                                    >
                                                        <h3 className="f-16 mb-0 info-username">
                                                            {item?.userName && item?.userName}
                                                        </h3>
                                                        <small className=" text-break">{item?.walletAddress}</small>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                                {errors.walletAddress && touched.walletAddress ? (
                                    <div className="text-danger fw-normal fs-12">
                                        {errors?.userName || errors?.walletAddress}
                                    </div>
                                ) : values?.quantity > tokenToSend?.balance ? (
                                    <p className="text-danger my-2" style={{ fontWeight: "500" }}>
                                        Insufficient balance!!
                                    </p>
                                ) : null}
                            </Form.Group>
                        </div>
                        {!loader ? (
                            <button
                                disabled={
                                    loader || +values.quantity > +tokenToSend?.balance || Object.values(errors).length
                                }
                                className="btn w-100 mt-3 p-3 connect-wallet-btn"
                                type="submit"
                            >
                                Send
                            </button>
                        ) : (
                            <button className="btn w-100 mt-3 p-3 connect-wallet-btn">
                                <span className="typeWriter">
                                    processing<span> . . . . .</span>
                                </span>
                            </button>
                        )}
                        <Contacts setWalletAddress={setWalletAddress} setUserName={setUserName} setUserFullName={setUserFullName} />
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default SendTab;

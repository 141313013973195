import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import { verifyMpin } from "../../services/userService";
import { fireToast } from "../../common/Toster";
import { FaCircleChevronLeft } from "react-icons/fa6";
import Modal from "react-bootstrap/Modal";
import OtpInput from "react-otp-input";

const PinConfirm = ({
  formData,
  handleSubmit,
  showPinModal,
  handleClosePinModal,
  setShow,
}) => {
  const [loader, setLoader] = useState(false);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      pin: "",
    },
    validationSchema: Yup.object({
      pin: Yup.string()
        .min(4, "Invalid PIN")
        .max(4, "Invalid PIN")
        .required("This Field is Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      console.log(formData, "formData");
      try {
        setLoader(true);
        const loginResponse = await verifyMpin({ mpin: values?.pin });
        if (loginResponse.status === 200) {
          fireToast("success", "PIN verified successfully.");
          setShow(true);
          handleSubmit(formData);
          resetForm();
          handleClosePinModal();
          setLoader(false);
        } else {
          throw new Error(loginResponse.error);
        }
      } catch (err) {
        console.log(err, "err");
        fireToast("error", err?.response?.data?.error);
        resetForm();
        setLoader(false);
      }
    },
  });
  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showPinModal}
        onHide={handleClosePinModal}
        animation={false}
      >
        <Modal.Body>
          <div
            className="back-btn fw-500 text-black"
            onClick={handleClosePinModal}
          >
            <i className="pe-2 align-middle">
              <FaCircleChevronLeft />
            </i>
          </div>
          <div className="p-2">
            <div className="text-center">
              <h1 className="fs-32 mt-3">PIN Verification</h1>
              <p className="f-14">
                Please enter the PIN to confirm transaction
              </p>
              <Form
                // className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Form.Group>
                  <Form.Label className="form-label text-start w-100">
                    Enter PIN
                  </Form.Label>
                  <div className="position-relative">
                    <OtpInput
                      name="pin"
                      inputType="password"
                      value={validation.values.pin}
                      onChange={(value) => {
                        validation.setFieldTouched("pin", true);
                        validation.setFieldValue("pin", value);
                      }}
                      numInputs={4}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                      inputStyle="inputStyle"
                      containerStyle={"otp-input-style"}
                    />
                    {/* <Form.Control
                      name="pin"
                      placeholder={"Enter PIN"}
                      type="text"
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.pin && validation.errors.pin
                          ? "true"
                          : "false"
                      }
                      render={({ field, meta }) => (
                        <>
                          <OtpInput
                            onChange={validation.handleChange}
                            numInputs={6}
                            value={validation.values.pin || ""}
                            separator={<span>-</span>}
                            inputStyle="inputStyle"
                          />
                        </>
                      )}
                    /> */}

                    {validation.touched.pin && validation.errors.pin ? (
                      // <Form.Control.Feedback type="invalid" className="text-danger">
                      //   {validation.errors.otp}
                      // </Form.Control.Feedback>
                      <div className="text-danger text-start error-msg">
                        {validation.errors.pin}
                      </div>
                    ) : null}
                  </div>
                </Form.Group>

                <div className="mt-3 d-grid">
                  {loader ? (
                    <Button
                      // disabled={loader}
                      className="common-btn mt-4 mb-3 w-100"
                      variant="primary"
                    >
                      <span className="typeWriter">
                        Processing<span> . . . . .</span>
                      </span>
                    </Button>
                  ) : (
                    <Button
                      className="common-btn mt-4 mb-3 w-100"
                      variant="primary"
                      type="submit"
                    >
                      <strong>Verify</strong>
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PinConfirm;

import SyncLoader from "react-spinners/SyncLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "#0052ff",
  background: "transparent",
};
const SyncLoading = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "300px" }}
    >
      <SyncLoader
        cssOverride={override}
        size={15}
        color="#0052ff"
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};
export default SyncLoading;

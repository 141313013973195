import { useState } from "react";
import "./Modal.css";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import send from "../../images/send.svg";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { SendRed } from "../../services/propertyServices";
import { fireToast } from "../../common/Toster";
const Details = ({ showProp, onClose, data }) => {
  // console.log(showProp,"modal data")
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showProp}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <span
            className="back-btn fw-500 text-black"
            onClick={() => handleClose()}
          >
            <i className="pe-2 align-middle">
              <FaCircleChevronLeft />
            </i>
            Transactions History
          </span>
          <div className="px-5 my-4"></div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Details;

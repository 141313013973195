import React, { useContext, useEffect, useState } from "react";
import "./Balancecard.css";
import { getMyInfo } from "../../services/propertyServices";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { fireToast } from "../../common/Toster";
import { CgArrowsExchangeAlt } from "react-icons/cg";
import { FaCopy } from "react-icons/fa6";
import Buyicon from "../../images/buy.svg";
import send from "../../images/send.svg";
import Receive from "../../images/recive.svg";
import { useNavigate, Link } from "react-router-dom";
import { ConversionContext } from "../../Context/context";

const Balancecard = ({ balance, address }) => {
  const [show, setshow] = useState(false);
  const [data, setData] = useState();
  let navigate = useNavigate();
  const { setShowSwapAssets, setActiveSwapTab, setActiveFierceTab, fierceId } = useContext(ConversionContext);
  useEffect(() => {
    getMyInfo()
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => {
        console.log(err, "err getting data");
      });
  }, []);

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    fireToast("success", "Copied!");
  };
  return (
    <>
      <div className="balance-card mx-auto p-3">
        <div className="token-head d-flex align-items-center justify-content-between">
          <div>
            <span className="text-white" style={{ fontSize: "18px" }}>
              Hello,
            </span>
            <h6 className=" text-white" style={{ fontSize: "16px" }}>
              {data?.name} {data?.name && data?.lastname}
            </h6>
          </div>
          <div className="text-end">
            <span className="text-white" style={{ fontSize: "18px" }}>
              Wallet Address
            </span>
            <h6 className=" text-white" style={{ fontSize: "16px" }}>
              {address?.slice(0, 3)}...{address?.slice(39, 42)}&nbsp;
              <span
                style={{ cursor: "pointer" }}
                onClick={() => handleCopy(address)}
              >
                <FaCopy />
              </span>
            </h6>
          </div>
        </div>
        {/* <div className="mt-4 text-center">
            <p className="f-14 fw-500 text-white mb-0">Total balance</p>
            <p className="f-24 fw-60 text-white mb-4">{!show ? '******' : `$ ${formatCompactNumber(((Number(balance)/Number(redUsd)) +( Number(balanceSr)/Number(srUsd)) )) }`} { show ? <span onClick={()=>setshow(!show)} style={{cursor:"pointer"}}> < AiFillEyeInvisible style={{marginBottom:'5px'}} /></span> 
            // <p className="f-24 fw-60 text-white mb-4">{!show ? '******' : `$ ${formatCompactNumber((Number(balance))+( Number(balanceSr))) }`} { show ? <span onClick={()=>setshow(!show)} style={{cursor:"pointer"}}> < AiFillEyeInvisible style={{marginBottom:'5px'}} /></span> 

            : <span style={{cursor:"pointer"}} onClick={()=>setshow(!show)}> <AiFillEye style={{marginBottom:'5px'}}  /></span>}</p>
        </div> */}
        <div className="mt-4 text-center">
          <p className="f-18 fw-500 text-white mb-0">Total Worth (in USD)</p>

          <p className="f-24 fw-60 text-white mb-4">
            {!show
              ? "******"
              : `$ ${Number(
                Number(balance)
                  .toString()
                  .match(/^-?\d+(?:.\d{0,2})?/)[0]
              ).toLocaleString()}`}{" "}
            {show ? (
              <span
                onClick={() => setshow(!show)}
                style={{ cursor: "pointer" }}
              >
                {" "}
                <AiFillEyeInvisible style={{ marginBottom: "7px" }} />
              </span>
            ) : (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setshow(!show)}
              >
                {" "}
                <AiFillEye style={{ marginBottom: "12px" }} />
              </span>
            )}
          </p>
        </div>
        <div
          className="row d-flex align-items-center text-center"
        >
          <span onClick={() => {
            setActiveSwapTab('send')
            setShowSwapAssets(true)
          }} className="col-3 justify-content-center text-center fs-8 mb-0 text-white fw-500 cursor-pointer">
            <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-0 receive-tkn position-relative mb-1">
              <img src={send} alt="" />
            </span>
            <div className="text-white scard-btext">Send</div>
          </span>
          <span className="dropdown col-3 justify-content-center text-center fs-8 mb-0 text-white fw-500 cursor-pointer">
            <span
              onClick={() => {
                if (!fierceId) {
                  fireToast('error', 'Your fierce account not found !');
                }
              }}
              id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
              className="col-3 justify-content-center text-center fs-8 mb-0 text-white fw-500 cursor-pointer"
            >
              <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
                <img src={Buyicon} alt="" />
              </span>
              <p className="scard-btext">Fierce</p>
            </span>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><Link to='/Swap' className={`dropdown-item ${!fierceId && 'disabled'}`} onClick={() => {
                if (fierceId) {
                  setActiveSwapTab('fierce');
                  setActiveFierceTab('buy')
                }
              }}>Buy</Link></li>
              <li><Link to='/Swap' className={`dropdown-item ${!fierceId && 'disabled'}`} onClick={() => {
                if (fierceId) {
                  setActiveSwapTab('fierce')
                  setActiveFierceTab('sell')
                }
              }}>Sell</Link></li>
            </ul>
          </span>
          <span onClick={() => {
            setActiveSwapTab('receive');
            navigate('/Swap');
          }} className="col-3 justify-content-center text-center fs-8 mb-0 text-white fw-500 cursor-pointer">
            <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-0 receive-tkn position-relative mb-1">
              <img src={Receive} alt="" />
            </span>
            <div className="text-white scard-btext">Receive</div>
          </span>
          <span
            onClick={() => {
              setActiveSwapTab('convert')
              // setShowSwapAssets(true)
              navigate('/Swap');
            }}
            className="col-3 justify-content-center text-center fs-8 mb-0 text-white fw-500 cursor-pointer"
          >
            <span className="token-btn d-flex align-items-center justify-content-center mx-auto mb-1">
              <CgArrowsExchangeAlt color="blue" fontSize={24} />
            </span>
            <p className="scard-btext">Convert</p>
          </span>
        </div>
      </div>
    </>
  );
};

export default Balancecard;
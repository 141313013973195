import React from "react";
import { useNavigate } from "react-router-dom";
import "./TvtCard.css";

const TvtCard = ({ data, fetchData, setLoading }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="tvtCard">
        <div className="border rounded p-3">
          <div
            className="d-flex align-items-center mb-3 pb-3"
            style={{
              justifyContent: "space-between",
              borderBottom: "1px solid #cecece",
            }}
          >
            <div className="d-flex align-items-center">
              <h6 className="fw-bold mb-0">{data?.categories}</h6>
            </div>
            <button className="buyButton btn btn-primary btn-sm ms-auto mt-2">
              TVT
            </button>
          </div>
          <figure
            className="tranding-img rounded position-relative text-center p-2"
            style={{
              height: "200px",
            }}
          >
            <img src={data?.image} alt="" />
          </figure>
          <div className="card-details">
            <div>
              <h6 className="fw-bold">{data?.series}</h6>
            </div>
            <div className="d-flex mcard-content">
              <span className="fw-500">Symbol </span>
              <span className="text-uppercase text-success ms-auto">
                {data?.symbol}
              </span>
            </div>
            <div className="d-flex mcard-content">
              <span className="fw-500">Price </span>
              <span className="text-uppercase text-success ms-auto">
                {Number(data?.tradePrice).toLocaleString()} RED
              </span>
            </div>
            <div className="d-flex mcard-content">
              <span className="fw-500">Quantity Available</span>
              <span className="text-uppercase text-success ms-auto">
                {Number(
                  data?.quantityForSellInSoloMarketPlace
                ).toLocaleString()}
              </span>
            </div>
            <div className="d-flex mcard-content">
              <span className="fw-500">My Holdings </span>
              <span className="text-uppercase text-success ms-auto">
                {data?.myHolding > 0
                  ? `${Number(data?.myHolding).toLocaleString() + " " + data?.symbol}`
                  : 0}
              </span>
            </div>
            <div className="d-flex mcard-content">
              <span className="fw-500">Current Value </span>
              <span className="text-uppercase text-success ms-auto">
                {data?.myHolding > 0
                  ? `${Number(data?.myHolding * data?.tradePrice).toLocaleString()} RED`
                  : 0}
              </span>
            </div>
            {/* <div className="d-flex">
              <small>{moment(data?.createdAt).fromNow()} </small>
            </div> */}
          </div>

          <div className="d-flex">
            <button
              className="buyButton btn btn-primary btn-sm ms-auto mt-2"
              onClick={() =>
                navigate("/tvt-details", {
                  state: { data: data, card: "buy" },
                })
              }
            >
              View Detail
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TvtCard;

import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./SellSrDetail.css";
import { ethers } from "ethers";
import SRABI from "../../Abis/SR_ABi.json";
import { ConversionContext } from "../../Context/context";
import { FaCircleChevronLeft } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import WaitingLoader from "../loader/waitingLoader";
import Loader from "../loader/loader";
import {
  fireToast,
  kycErrorPopUp,
  maintenancemodePopup,
} from "../../common/Toster";
import { getUserDataWithWallet } from "../../services/NftServices";
import Modal from "react-bootstrap/Modal";
import PinConfirm from "../Modals/PinConfirm";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useAddress, useContract, useSigner } from "@thirdweb-dev/react";
import configURl from "../../runtime.config";
import { sellSolosTransaction } from "../../services/propertyServices";

const SellSrDetail = () => {
  const [showPinModal, setShowPinModal] = useState(false);
  const [formData, setFormData] = useState({});
  const { contract } = useContract(configURl.redNewAddress);
  const signer = useSigner();
  const myAddress = useAddress();
  const navigate = useNavigate();
  const [openBuy, setOpenBuy] = useState(false);
  const [openRevoke, setOpenRevoke] = useState(false);
  const [loading, setLoading] = useState(false);
  const contextData = useContext(ConversionContext);
  const userDataContext = contextData?.userData;
  const location = useLocation();
  const [waitLoader, setWaitLoader] = useState(false);
  const data = location?.state?.data;
  const conversionRate = contextData?.conversionRate;
  const handleBuyClose = () => setOpenBuy(false);
  const isPinActive = contextData?.userData?.isMpinActive;
  const handleRevokeClose = () => setOpenRevoke(false);
  const [totalRedAmount, setTotalRedAmount] = useState("");
  const BuySchema = Yup.object().shape({
    quantity: Yup.number()
      .required("Please enter Quantity")
      .notOneOf([0], "Quantity must be greater than 0")
      .min(0, "Quantity must be greater than or equal to 0")
      .max(
        data?.quantity / 10 ** 18,
        `Only ${data?.quantity / 10 ** 18} SR are available`
      ),
  });
  const RevokeSchema = Yup.object().shape({
    quantity: Yup.number()
      .required("Please enter Quantity")
      .notOneOf([0], "Quantity must be greater than 0")
      .min(0, "Quantity must be greater than or equal to 0")
      .max(
        data?.quantity / 10 ** 18,
        `Only ${data?.quantity / 10 ** 18} SR are available`
      ),
  });
  const [userWalletData, setUserWallerData] = useState("");
  useEffect(() => {
    getUserDataWithWallet(data?.address)
      .then((res) => {
        // console.log(res?.data, "user data with wallet address");
        setUserWallerData(res?.data);
      })
      .catch((err) => {
        console.log(err, "errrr of wallet address ");
      });
  }, []);
  const handleClosePinModal = () => {
    setShowPinModal(false);
  };
  const buy = async () => {
    setOpenBuy(true);
  };

  const revoke = async (data) => {
    setOpenRevoke(true);
  };
  const onRevoke = async (values) => {
    try {
      setWaitLoader(true);
      setLoading(true);
      const srContract = new ethers.Contract(
        data?.contractAddress,
        SRABI,
        signer
      );
      const maticBalance = ethers.utils.formatEther(await signer.getBalance());
      // const gasPrice = await signer.getGasPrice();
      const gasPrice = ethers.utils.parseUnits("500", "gwei");
      // const estimatedGasPrice = ethers.utils.formatEther(gasPrice);
      if (+maticBalance < configURl.minMaticLimit) {
        fireToast("error", "Insufficient matic funds for gas price !");
        setWaitLoader(false);
        setLoading(false);
        return;
      }

      const decimals = await srContract.decimals();
      const newAmount = ethers.utils.parseUnits(
        `${values?.quantity}`,
        decimals
      );
      await srContract
        .releaseSR(newAmount, {
          maxFeePerGas: gasPrice,
          maxPriorityFeePerGas: gasPrice,
          gasLimit: 600000,
        })
        .then(async (response) => {
          setLoading(false);
          setWaitLoader(false);
          console.log(response, "Revoking Trnx.");
          navigate("/marketplace");
          fireToast("success", "Successfully Revoked.");
        });
    } catch (error) {
      console.error("Error revoking SR:", error);
      setWaitLoader(false);
      setLoading(false);
      fireToast("error", "Something went wrong please try again later !");
    }
  };

  /* facetec kyc hide */
  // const isKycVerified =
  //   localStorage.getItem("isKycCompleted") === "true" ? true : false;

  const onBuy = (values) => {
    (isPinActive
      ? () => {
        setOpenBuy(false);
        setFormData(values);
        setShowPinModal(true);
      }
      : () => {
        onBuyFunction(values);
      })();
  };

  const onBuyFunction = async (values) => {
    try {
      setWaitLoader(true);
      setLoading(true);
      const maticBalance = ethers.utils.formatEther(await signer.getBalance());
      // const gasPrice = await signer.getGasPrice();
      const gasPrice = ethers.utils.parseUnits("500", "gwei");
      // const estimatedGasPrice = ethers.utils.formatEther(gasPrice);
      if (+maticBalance < configURl.minMaticLimit) {
        fireToast("error", "Insufficient matic funds for gas price !");
        setWaitLoader(false);
        setLoading(false);
        return;
      }
      let amount = `${values?.quantity * data?.balance}`;
      let balance = await contract.erc20.balanceOf(myAddress);
      if (+balance.displayValue < +amount) {
        fireToast("error", "Transfer amount exceeds balance !");
        setWaitLoader(false);
        setLoading(false);
        return;
      }
      const redContract = new ethers.Contract(
        configURl.redNewAddress,
        contract.abi,
        signer
      );
      const redDecimals = await redContract.decimals();
      const approvedAmount = await redContract.allowance(
        myAddress,
        data?.contractAddress
      );
      let formattedApprovedAmount = ethers.utils.formatUnits(
        approvedAmount,
        redDecimals
      );
      console.log(formattedApprovedAmount, "formattedApprovedAmount");
      if (+formattedApprovedAmount < +amount) {
        console.log("ask for approve");
        const approveTrnx = await redContract.approve(
          data?.contractAddress,
          "1000000000000000000000000",
          {
            maxFeePerGas: gasPrice,
            maxPriorityFeePerGas: gasPrice,
            gasLimit: 600000,
          }
        );
        console.log(approveTrnx, "approved");
        const approved = await approveTrnx.wait();
        console.log(approved, "approved");
      }
      const contractInstance = new ethers.Contract(
        data?.contractAddress,
        SRABI,
        signer
      );
      const decimals = await contractInstance.decimals();
      const hexQuantity = ethers.utils.parseUnits(values?.quantity, decimals);
      const response = await contractInstance.spendLockedSR(
        data?.address,
        hexQuantity,
        {
          maxFeePerGas: gasPrice,
          maxPriorityFeePerGas: gasPrice,
          gasLimit: 600000,
        }
      );
      console.log(response, "spendLockedSR trnx.");
      const requestData = {
        hashId: response?.hash,
        solosReward: values?.quantity,
        totalRedToken: amount,
        walletAddressFrom: data?.address,
        walletAddressTo: myAddress,
      };
      await sellSolosTransaction(requestData).then(
        (response) => {
          console.log(response, "sellSolosTransaction api response.");
          setWaitLoader(false);
          setLoading(false);
          fireToast("success", "SR buy successfully.");
          navigate("/marketplace");
        },
        (error) => {
          console.log(error, "sellSolosTransaction api error.");
          setWaitLoader(false);
          setLoading(false);
          fireToast("error", "Something went wrong.");
        }
      );
    } catch (error) {
      setWaitLoader(false);
      setLoading(false);
      console.log(error);
      fireToast("error", "Something went wrong please try again later !");
    }
  };

  return waitLoader ? (
    <WaitingLoader />
  ) : loading ? (
    <Loader />
  ) : (
    <div className="nftDetail ps-4">
      <Link
        className="back-btn fw-500 text-black pt-4"
        onClick={() => navigate(-1)}
      >
        <i className="pe-2 align-middle">
          <FaCircleChevronLeft />
        </i>
        Back
      </Link>
      <div className="dashboard-centerd">
        <div className="row g-0">
          <div className="col-xl-12 col-xxl-12 pe-4 pt-4 border-end center-height pb-5">
            <div className="row mb-5">
              <div className="col-6 sideImage">
                <figure
                  className="mb-0 imageFigure"
                  style={{
                    background: `url(${data?.image})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  {/* <img src={data?.Image} /> */}
                </figure>
              </div>
              <div className="col-6 sideText">
                <div className="mainText mb-3">
                  <h4 style={{ color: "#000" }}>{data?.name}</h4>
                  <h6 className="fw-bold" style={{ color: "green" }}>
                    {data?.balance} RED{" "}
                    {`($${(data?.balance * conversionRate)
                      .toString()
                      .match(/^-?\d+(?:.\d{0,2})?/)?.[0]
                      })`}
                  </h6>
                </div>
                <div className="mb-2">
                  <span style={{ fontWeight: "600" }}>Name : </span>
                  <span>{data?.name}</span>
                </div>
                <div className="mb-2">
                  <span style={{ fontWeight: "600" }}>Initial Price : </span>
                  <span>1 RED</span>
                </div>
                {/* <div className="mb-2">
                  <span style={{ fontWeight: "600" }}>Reward Amount : </span>
                  <span>
                    {data?.paymentAmount} SR{" "}
                    {data?.paymentDate != "none" &&
                      `on ${data?.paymentDate} every
                    ${data?.type}`}
                  </span>
                </div> */}

                {/* <div className="mb-3">
                  <span style={{ fontWeight: "600" }}>Issued Date : </span>
                  <span>{momentTimeZone.utc(data?.issuedDate).tz('America/Los_Angeles').format("DD-MMM-YYYY")}</span>
                </div> */}
                {/* <div className="descText">
                  <h6 style={{ color: "#000" }}>Description</h6>
                  <p>{data?.description}</p>
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-6 collectionDetails">
                <h6 className="fw-bold" style={{ color: "#000" }}>
                  Collection Details
                </h6>
                <div className="row mb-3">
                  {/* <div className="col-6 mb-2">
                    <FaCalendar /> Published by{" "}
                    <span className="fw-bold" style={{ color: "#000" }}>
                      {data?.publishedBy}
                    </span>{" "}
                  </div> */}
                  {/* <div className="col-6 mb-2">
                    <FaClock /> Published{" "}
                    <span className="fw-bold" style={{ color: "#000" }}>
                      {momentTimeZone.utc(data?.updatedAt).tz('America/Los_Angeles').format("DD-MM-YYYY")}
                    </span>{" "}
                  </div> */}
                  {/* <div className="col-6 mb-2">
                    <FaCalendar /> Available From{" "}
                    <span className="fw-bold" style={{ color: "#000" }}>
                      {momentTimeZone.utc(data?.updatedAt).tz('America/Los_Angeles').fromNow()}
                    </span>
                  </div> */}
                </div>
                <div className="p-3 mintNowText">
                  <h6 style={{ color: "#000" }} className="mb-3">
                    Buy now to get your SR
                  </h6>
                  <h6 style={{ color: "#000" }} className="mb-3">
                    Price per SR: {data?.balance} RED
                  </h6>
                  {/* <h6 className="mb-3">
                    {momentTimeZone.utc(data?.updatedAt).tz('America/Los_Angeles').format("DD-MM-YYYY")}{" "}
                    {momentTimeZone.utc(data?.updatedAt).tz('America/Los_Angeles').format("h:mm a")}
                  </h6> */}
                  <h6 className="mb-3">
                    Listed QTY : {data?.quantity / 10 ** 18}{" "}
                  </h6>
                  {userWalletData?.name && (
                    <h6 className="mb-3">
                      Listed By : {userWalletData?.name}{" "}
                      {userWalletData?.lastname}
                    </h6>
                  )}

                  {data?.address?.toLowerCase() != myAddress?.toLowerCase() ? (
                    <div className="mintButton">
                      <button
                        className="btn"
                        onClick={() => {
                          if (userDataContext?.maintenanceMode) {
                            maintenancemodePopup();
                          }
                          // else if (isKycVerified) {
                          //   buy(data);
                          // } 
                          else {
                            buy(data);
                            // kycErrorPopUp();
                          }
                        }}
                      >
                        Buy Now
                      </button>
                    </div>
                  ) : (
                    <div className="mintButton">
                      <button className="btn" onClick={() => revoke(data)}>
                        Revoke
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openBuy}
        onHide={handleBuyClose}
        animation={false}
      >
        <Modal.Header>
          <h5 className="" style={{ margin: "auto" }}>
            {" "}
            Buy SR
          </h5>
          <span
            style={{ cursor: "pointer" }}
            className=" d-flex justify-content-end mb-2"
            onClick={handleBuyClose}
          >
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                quantity: "",
              }}
              validationSchema={BuySchema}
              onSubmit={onBuy}
              enableReinitialize
            >
              {({ errors, touched, values, setFieldValue, setTouched }) => (
                <Form className="sellNFT-form">
                  <div className="row align-items-start justify-content-center">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="mb-2" htmlFor="propertyName">
                            Quantity:
                          </label>
                          <Field
                            name="quantity"
                            id="quantity"
                            placeholder="Enter Quantity"
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("quantity", e.target.value);
                              setTotalRedAmount(e.target.value * data?.balance);
                            }}
                          />
                          {errors.quantity && touched.quantity ? (
                            <div className="input-error">{errors.quantity}</div>
                          ) : null}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="mb-2" htmlFor="propertyName">
                            Price (In RED):
                          </label>
                          <Field
                            value={totalRedAmount}
                            name="priceinred"
                            id="priceinred"
                            placeholder="Enter Price in RED"
                            className="form-control"
                            disabled
                          />
                          {errors.priceinred && touched.priceinred ? (
                            <div className="input-error">
                              {errors.priceinred}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <Button
                        className="w-100 common-btn1"
                        variant="primary"
                        type="submit"
                      >
                        Buy
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openRevoke}
        onHide={handleRevokeClose}
        animation={false}
      >
        <Modal.Header>
          <h5 className="" style={{ margin: "auto" }}>
            {" "}
            Revoke FRED
          </h5>
          <span
            style={{ cursor: "pointer" }}
            className=" d-flex justify-content-end mb-2"
            onClick={handleRevokeClose}
          >
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                quantity: "",
              }}
              validationSchema={RevokeSchema}
              onSubmit={onRevoke}
              enableReinitialize
            >
              {({ errors, touched, values, setFieldValue, setTouched }) => (
                <Form className="sellNFT-form">
                  <div className="row align-items-start justify-content-center">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="mb-2" htmlFor="propertyName">
                            Quantity:
                          </label>
                          <Field
                            name="quantity"
                            id="quantity"
                            placeholder="Enter Quantity"
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("quantity", e.target.value);
                            }}
                          />
                          {errors.quantity && touched.quantity ? (
                            <div className="input-error">{errors.quantity}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <Button
                        className="w-100 common-btn1"
                        variant="primary"
                        type="submit"
                      >
                        Revoke
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <PinConfirm
        setShow={setOpenBuy}
        formData={formData}
        showPinModal={showPinModal}
        handleSubmit={onBuyFunction}
        handleClosePinModal={handleClosePinModal}
      />
    </div>
  );
};

export default SellSrDetail;

// import { get, post, postAsForm, put } from "helpers/api_helper.js";
import { get, post, postAsForm, put } from "../helpers/api_helper";
import appendData from "../utils/formData";

export const userLogin = (data) => {
  return post("/client/login", data);
};
export const userRegister = (data) => {
  return post("/client/register", data);
};
export const verifyMpin = (data) => {
  return post("/client/verifyMpin", data);
};
export const saveBasicInfo = (data) => {
  return post("/client/registerData", data);
};
export const userRegister2 = (data) => {
  return post("/client/register2", data);
};
export const userVerifyotp = (data) => {
  return post("/client/verifyotp", data);
};
export const userVerifyotp2 = (data) => {
  return post("/client/verifyotp2", data);
};
export const googleAuthentication = (data) => {
  return put("/admin/googleAuthentication", data);
};
export const VerifyLogin = (data) => {
  return put("/client/verifyotpadmin", data);
};
export const forgetPassword = (data) => {
  return post("/client/forgotPassword", data);
};
export const verifyOtp = (data) => {
  return put("/client/verifyOtp", data);
};
export const resetPassword = (new_password, token) => {
  return post(
    "/client/resetPassword",
    { new_password },
    {
      headers: { Authorization: token },
    }
  );
};
export const getUserProfile = () => {
  return get("/client/me");
};
export const updateProfile = (data) => {
  const formData = appendData(data);
  return postAsForm("/client/update", formData);
};
export const changePassword = (data) => {
  return post("/client/changePassword", data);
};
export const updateWithdrawStatus = (data) => {
  return put("/client/updateWithdrawStatus", data);
};
export const verifyKyc = (data) => {
  return post("/client/verifyKyc", data);
};

import configURl from "./runtime.config";
// console.log(
//   configURl?.thirdweb_client_id,
//   "configURl?.thirdweb_client_id",
//   configURl?.thirdweb_secret_key,
//   "configURl?.thirdweb_secret_key"
// );
const variables = {
  thirdweb_client_id: configURl?.thirdweb_client_id,
  thirdweb_secret_key: configURl?.thirdweb_secret_key,
};
export default variables;

import { useState } from "react";
import "./Modal.css";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Recive from "../../images/recive.svg";
import ReferImg from "../../images/referImg.png";
import FacebookIcon from "../../images/facebookIcon.png";
import InstaIcon from "../../images/instaIcon.png";
import WtsupIcon from "../../images/wtsupIcon.png";
import MailIcon from "../../images/mailIcon.png";
import { fireToast } from "../../common/Toster";
import OptionIcon from "../../images/optionIcon.png";
import TelegramIcon from "../../images/telegramIcon.png";
import CopyIcon from "../../images/copyIcon.png";
import ShareIcon from "../../images/shareIcon.png";
import TwitterIcon from "../../images/twitterIcon.png";
import { RWebShare } from "react-web-share";
import { useEffect } from "react";
import { getMyInfo } from "../../services/propertyServices";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";

const ReferModal = ({ show, setShow }) => {
  const handleClose = () => setShow(false);
  const [myRef, setMyRef] = useState("");

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    fireToast("success", "Copied!");
  };

  const getdata = async () => {
    await getMyInfo()
      .then((res) => {
        setMyRef(res?.data?.myReferralCode);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          <Link
            className="back-btn fw-500 text-black mt-4"
            onClick={() => setShow(false)}
          >
            <i className="pe-2 align-middle">
              <FaCircleChevronLeft />
            </i>
            Invite Friend
          </Link>
          <figure className="tranding-img rounded position-relative text-center p-2">
            <img src={ReferImg} />
          </figure>
          <div>
            <h6>Refer via</h6>
            <div className="row py-4">
              <div className="col text-center">
                <FacebookShareButton
                  url="users.solosfi.com"
                  quote={`Hey checkout this awesome app https://solosfi.com and use my Referral code while registering ${myRef}`}
                  //   quote="Please share this post"
                  hashtag="#referral"
                >
                  <img height={50} src={FacebookIcon} />
                </FacebookShareButton>
              </div>
              <div className="col text-center">
                <TwitterShareButton
                  //   url="users.solosfi.com"
                  url={`Hey checkout this awesome app https://solosfi.com and use my Referral code while registering ${myRef}`}
                  quote="Please share this post"
                  hashtag="#code"
                >
                  <img height={50} src={TwitterIcon} />
                </TwitterShareButton>
              </div>
              <div className="col text-center">
                <WhatsappShareButton
                  url={`Hey checkout this awesome app https://solosfi.com and use my Referral code while registering ${myRef}`}
                  //   url="users.solosfi.com"
                  quote="Please share this post"
                  hashtag="#referral"
                >
                  <img height={50} src={WtsupIcon} />
                </WhatsappShareButton>
              </div>
              <div className="col text-center">
                <TelegramShareButton
                  url={`Hey checkout this awesome app https://solosfi.com and use my Referral code while registering ${myRef}`}
                  quote="Please share this post"
                  hashtag="#code"
                >
                  <img height={50} src={TelegramIcon} />
                </TelegramShareButton>
              </div>
              <div className="col text-center">
                <RWebShare
                  data={{
                    url: `Hey checkout this awesome app https://solosfi.com and use my Referral code while registering ${myRef}`,
                    // url: "users.solosfi.com",
                  }}
                  //   onClick={() => console.log("shared successfully!")}
                >
                  <img height={50} src={OptionIcon} />
                </RWebShare>
                {/* <img height={50} src={OptionIcon} /> */}
              </div>
            </div>
            <div className="referral-box mb-4">
              <div className="text">
                <h6 style={{ fontSize: "14px" }}>Referral code</h6>
                <b style={{ fontSize: "16px" }}>{myRef}</b>
              </div>
              <div className="icons d-flex">
                <img
                  className="mx-2"
                  height={40}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleCopy(myRef)}
                  src={CopyIcon}
                />
                <RWebShare
                  data={{
                    url: `Hey checkout this awesome app https://solosfi.com and use my Referral code while registering ${myRef}`,
                    // url: { myRef },
                  }}
                  //   onClick={() => console.log("shared successfully!")}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    height={40}
                    src={ShareIcon}
                  />
                </RWebShare>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReferModal;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import RedToken from "../components/Red-Token/RedToken";
import SolosReward from "../components/SolosReward/SolosReward";
import BooqBalance from "../components/BooqBalance/BooqBalance";
import Possession from "../components/Possessions/Possession";
import Comingsoon from "../images/coming.png";
import Balancecard from "../components/Balancecard/Balancecard";
import Transactions from "../components/Transactions/Transactions";
import { getDashboardLayout } from "../components/Layout/Layout";
import Loader from "../components/loader/loader";
import { walletData } from "../services/propertyServices";
import { ConnectWallet, useAddress } from "@thirdweb-dev/react";
import MyAssets from "../components/MyAssets";
import { userRegisterOnBridge } from "../services/bridgeServices";
import { fireToast } from "../common/Toster";
import {
  getSoloAssets,
  //  walletData
} from "../services/propertyServices";
import FierceCard from "../components/FierceCard/FierceCard";
import UsdcCard from "../components/Usdc-Card/Usdc";

const Dashboard = () => {
  const address = useAddress();
  const [loader, setLoader] = useState(true);
  const [items, setItems] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [data, setData] = useState({ redToken: 0, usd: 0 });
  const fetchWalletData = async () => {
    const wallet = await walletData();
    setData(wallet?.data);
  };

  const registerUserOnBridge = async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const signedAgreementId = queryParams.get("signed_agreement_id");

      let userInfo = localStorage.getItem("user-info") || null;
      userInfo = userInfo ? JSON.parse(userInfo) : null;

      console.log(userInfo?.data, "user infomration dashboard")

      if (signedAgreementId && !userInfo?.data.bridgeCustomerId) {
        let result = await userRegisterOnBridge({
          signed_agreement_id: signedAgreementId.toString(),
          phone: userInfo?.data?.countryCode + userInfo?.data?.contact,
          email: userInfo?.data?.email,
          last_name: userInfo?.data?.lastname,
          first_name: userInfo?.data?.name,
        });
        if (result.status == 200) {
          // console.log(result, "user register on bridge>>>")

          userInfo.data.bridgeCustomerId = result.data.id;
          setUserInfo(userInfo.data);
          localStorage.setItem("user-info", JSON.stringify(userInfo));
        }
      }

      // console.log(signedAgreementId, "signedAgreementId");
    } catch (error) {
      console.log(error, "user bridge error")
      fireToast(
        "error",
        error?.response?.data?.error
          ? error?.response?.data?.error
          : error.message
            ? error.message
            : "Something went wrong!"
      );
    }
  };

  const getUserInfoLocal = async () => {
    try {
      let userInfo = localStorage.getItem("user-info") || null;
      userInfo = userInfo ? JSON.parse(userInfo) : null;
      setUserInfo(userInfo?.data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getUserInfoLocal();
    fetchWalletData();
    registerUserOnBridge();
  }, []);

  const getTokenBalance = async () => {
    setLoader(true);
    await getSoloAssets(address)
      .then((response) => {
        // console.log(response, "getSoloAssets");
        setItems(response?.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  };
  console.log(address, "address");
  useEffect(() => {
    if (address) {
      getTokenBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);
  const redTokenData = items?.find((item) => item?.symbol === "RED");
  const SRTokenData = items?.find((item) => item?.symbol === "SR");
  const UsdcTokenData = items?.find((item) => item?.symbol === "USDC");
  console.log(loader, "loader");
  return loader ? (
    <Loader />
  ) : (
    <>
      <div className="ps-lg-4 ps-3">
        <div className="dashboard-centerd mb-5">
          <div className="row g-0">
            <div className="col-xl-8 col-xxl-9 pe-lg-4 pe-3 pt-4 border-end center-height mb-0">
              {/* <div className="d-flex align-items-center justify-content-between mb-4">
                <h6 className="fw-600 text-black">Your Accounts</h6>
              </div> */}

              <div className="row mb-mb-5 gy-4">
                <div className="col-xxl-3 col-lg-6 col-12 ">
                  <RedToken
                    prop={data}
                    redTokenData={redTokenData}
                    getTokenBalance={getTokenBalance}
                  />
                </div>
                <div className="col-xxl-3 col-lg-6 col-12">
                  <SolosReward
                    data={data}
                    SRTokenData={SRTokenData}
                    getTokenBalance={getTokenBalance}
                  />
                </div>
                <div className="col-xxl-3 col-lg-6 col-12">
                  <UsdcCard
                    userInfo={userInfo}
                    UsdcTokenData={UsdcTokenData}
                    getTokenBalance={getTokenBalance}
                  />
                </div>
                <div className="col-xxl-3 col-lg-6 col-12">
                  <FierceCard userInfo={userInfo} />
                  {/* <BooqBalance
                    userInfo={userInfo}
                    getTokenBalance={getTokenBalance}
                    UsdcTokenData={UsdcTokenData}
                  /> */}
                </div>
              </div>
              <div className="mt-5">
                <Transactions pageSizeProp={5} />
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4">
                {/* <h6 className="fw-600 text-black">Your Accounts</h6> */}
                {/* <Link className="view-btn" to="/dashboard">
                  view all <BiChevronRight />
                </Link> */}
              </div>

              {/* <div className="row mb-5">
                <div className="col-lg-4 col-12">
                  <Possession />
                </div>
                <div className="col-lg-4 col-12">
                  <Possession />
                </div>
                <div className="col-lg-4 col-12">
                  <Possession />
                </div>
              </div> */}

              {/* <div className="d-flex align-items-center justify-content-between mb-3">
                <h6 className="fw-600 text-black">Future RED</h6>
              </div> */}

              {/* <div className="row">
                <div className="col-lg-4 col-sm-6">
                  <img src={Comingsoon} alt="" />
                </div>
              </div> */}
            </div>
            <div className="col-xl-4 col-xxl-3">
              <div className="right-sidebar px-xl-3 pe-4 pt-lg-5 ">
                <MyAssets />
                {/* <Transactions data={"Red"} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;

import { useEffect, useState } from "react";
import variables from "../Config";
import Loader from "../components/loader/loader";

const RecoverWallet = () => {
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoader(false)
        }, 1000);
    }, []);
    return (
        <>
            {loader && <Loader />}
            <iframe
                height={500}
                hidden={loader}
                className="w-100"
                title="recover-wallet"
                src={`https://embedded-wallet.thirdweb.com/sdk/2022-08-12/embedded-wallet/export?clientId=${variables.thirdweb_client_id}`}
            />
        </>
    );
};

export default RecoverWallet;

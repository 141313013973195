import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { IMAGE_URL } from "../../helpers/api_helper";
import { FaCircleChevronLeft, FaCopy } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./NftDetail.css";
import AdminContractABI from "../../Abis/AdminContractAbi.json";
import BuyNftAbi from "../../Abis/BuyNftAbi.json";
import { ethers } from "ethers";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import { AiOutlineClose } from "react-icons/ai";
import { FaCalendar, FaClock } from "react-icons/fa";
import { ConversionContext } from "../../Context/context";
import Loader from "../loader/loader";
import {
  transferNFT,
  getNftCategories,
  sellNftOnUserMarketplace,
} from "../../services/NftServices";
import { fireToast, kycErrorPopUp } from "../../common/Toster";
import Modal from "react-bootstrap/Modal";
import { redAddress, polygonMainnetUrl } from "../../Contracts/usdc";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import RedAbi from "../../Abis/RedAbi.json";

function capitalize(string) {
  return string
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : null;
}

const NftDetail = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const contextData = useContext(ConversionContext);
  const location = useLocation();
  const data = location?.state?.data;
  const card = location?.state?.card;
  // const adminPrivateKey = location?.state?.adminPrivateKey;
  const adminReceiveWalletAddress = location?.state?.adminReceiveWalletAddress;
  // console.log(adminPrivateKey, adminReceiveWalletAddress, "locations");
  const conversionRate = contextData?.conversionRate;
  const handleClose = () => setShow(false);

  /* facetec kyc hide */
  // const isKycVerified =
  //   localStorage.getItem("isKycCompleted") === "true" ? true : false;

  const nftImport = async (item) => {
    setLoading(true);
    const res = await window.ethereum
      .request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: item?.contractAddress,
            symbol: item?.symbol,
            decimals: 0,
            image: item?.Image,
            tokenId: "0",
          },
        },
      })
      .then((result) => {
        fireToast("success", "NFT imported to metamask successfully");
        // console.log("result??????");
        // console.log(result, "autoimportresult");
        setLoading(false);
      })
      .catch((err) => {
        fireToast("error", err.message);
        console.log(err, "autoimporterror");
        setLoading(false);
      });
    // console.log(res, "res");
  };

  // const buy = async (item) => {
  //   setLoading(true);
  //   //admin nft work
  //   //admin pvt key
  //   const maticProvider = new ethers.providers.JsonRpcProvider(
  //     polygonMainnetUrl
  //   );
  //   const adminSigner = new ethers.Wallet(adminPrivateKey, maticProvider);

  //   const adminContractAddress = item.contractAddress;
  //   const adminContract = new ethers.Contract(
  //     adminContractAddress,
  //     AdminContractABI,
  //     adminSigner
  //   );
  //   const tokenDecimals = "18";
  //   const balanceInHash = ethers.utils.parseUnits(
  //     item?.price.toString(),
  //     tokenDecimals
  //   );

  //   // if (window.web3 && window.web3.currentProvider && window.web3.currentProvider.isCoinbaseWallet) {
  //   if (
  //     window.web3 &&
  //     window.web3.currentProvider &&
  //     window.web3.currentProvider.isCoinbaseWallet
  //   ) {
  //     try {
  //       await window.ethereum.request({
  //         method: "wallet_switchEthereumChain",
  //         params: [{ chainId: "0x89" }],
  //         // params: [{ chainId: '0x5' }],
  //       });
  //     } catch (e) {
  //       fireToast("error", "USER REJECTED THE REQUEST...");
  //       setLoading(false);
  //     }
  //     // const provider = new ethers.providers.Web3Provider(window.web3.currentProvider);;
  //     const provider = new ethers.providers.Web3Provider(
  //       window.web3.currentProvider
  //     );
  //     await provider
  //       .send("eth_requestAccounts", [])
  //       .then(async (res) => {
  //         console.log(res);
  //         setAddress(res?.[0] ? res?.[0] : res?.result?.[0]);
  //         const signer = provider.getSigner();
  //         try {
  //           const REDContractInstance = new ethers.Contract(
  //             redAddress,
  //             RedAbi,
  //             signer
  //           );
  //           await REDContractInstance.transfer(
  //             adminReceiveWalletAddress,
  //             balanceInHash,
  //             { gasLimit: 250000 }
  //           )
  //             .then(async (result) => {
  //               console.log(result, "resultRED");
  //               console.log(address, "address");
  //               await adminContract
  //                 .transferFrom(
  //                   adminReceiveWalletAddress,
  //                   res?.[0] ? res?.[0] : res?.result?.[0],
  //                   0,
  //                   { gasLimit: 250000 }
  //                 )
  //                 .then(async (responseNFT) => {
  //                   console.log(responseNFT, "resultNFT");
  //                   try {
  //                     const response = await transferNFT({
  //                       amount: item?.price,
  //                       nftDetailId: item?._id,
  //                       hashId: responseNFT?.hash,
  //                       type: "normal",
  //                     })
  //                       .then(async (res1) => {
  //                         console.log(item, "item");
  //                         setLoading(false);
  //                         fireToast("success", "NTF buy successfully");
  //                         navigate("/marketplace");
  //                         // fetchData();

  //                         // const res = await window.ethereum.request({
  //                         //   method: "wallet_watchAsset",
  //                         //   params: {
  //                         //     type: "ERC721",
  //                         //     options: {
  //                         //       address: item?.contractAddress,
  //                         //       symbol: item?.symbol,
  //                         //       decimals: 0,
  //                         //       image: `https://foo.io/token-image.svg`,
  //                         //       tokenId: "1",
  //                         //     },
  //                         //   },
  //                         // }).then((result) => {
  //                         //   console.log('result??????')
  //                         //   console.log(result,'autoimportresult')
  //                         // }).catch((err)=>{
  //                         //   console.log(err,"autoimporterror")
  //                         // })
  //                         // console.log(res, "res");
  //                       })
  //                       .catch((errApi) => {
  //                         setLoading(false);
  //                         fireToast("error", errApi);
  //                       });
  //                   } catch (error) {
  //                     console.error("Error fetching nft data:", error);
  //                     setLoading(false);
  //                     fireToast("error", error);
  //                   }
  //                 })
  //                 .catch((err) => {
  //                   setLoading(false);
  //                   console.log(err, "errorNFT");
  //                   fireToast("error", err);
  //                 });
  //             })
  //             .catch((e) => {
  //               console.log(e.message, "errorRED");
  //               setLoading(false);
  //               fireToast(
  //                 "error",
  //                 e.message
  //                   ? e.message.includes("user rejected transaction")
  //                     ? "User Rejected Transaction"
  //                     : "Error"
  //                   : "Something Went Wrong, Please try again."
  //               );
  //             });

  //           // if (data) {
  //           //   let hash = data?.hash;
  //           //   // transferNFT(item, hash,'normal')
  //           // } else {
  //           //   return;
  //           // }
  //         } catch (error) {
  //           fireToast("error", error);
  //           console.log(error);
  //           setLoading(false);
  //         }
  //       })
  //       .catch((e) => {
  //         setLoading(false);
  //         console.log(e, ">>>>>>>>>>>>ERROR");
  //       });
  //   } else {
  //     setLoading(false);
  //     fireToast("error", "Metamask not detected");
  //   }
  // };
  const fetchCategoriesData = async (pageNumber = 1, pageSize = 8) => {
    // console.log("test");
    try {
      const list = await getNftCategories(pageNumber, pageSize);
      if (list.status === 200) {
        // console.log(list, "list");
        setCategories(list?.data?.data);
      } else {
        throw new Error(list.error);
      }
    } catch (err) {}
  };

  const Schema = Yup.object().shape({
    name: Yup.string().required("Please enter NFT name"),
    symbol: Yup.string().required("Please enter NFT Symbol"),
    price: Yup.string().required("Please enter NFT Price"),
    categoryId: Yup.string().required("Please enter NFT Category"),
    description: Yup.string().required("Please enter NFT description"),
  });

  const onSubmit = async (values) => {
    // console.log(values, "vals");
    try {
      setLoading(true);
      const response = await sellNftOnUserMarketplace({
        price: values?.price,
        nftId: values?.nftId,
        type: "nft",
      })
        .then(async (res) => {
          setLoading(false);
          fireToast("success", "NFT transfered to marketplace");
          navigate("/marketplace", { state: { data: "usermarket" } });
        })
        .catch((errApi) => {
          setLoading(false);
          fireToast("error", errApi);
        });
    } catch (error) {
      console.error("Error fetching nft data:", error);
      setLoading(false);
      fireToast("error", error);
    }
  };

  useEffect(() => {
    fetchCategoriesData();
  }, []);
  return loading ? (
    <Loader />
  ) : (
    <div className="nftDetail ps-4">
      <Link
        className="back-btn fw-500 text-black pt-4"
        onClick={() => navigate(-1)}
      >
        <i className="pe-2 align-middle">
          <FaCircleChevronLeft />
        </i>
        Back
      </Link>
      <div className="dashboard-centerd">
        <div className="row g-0">
          <div className="col-xl-12 col-xxl-12 pe-4 pt-4 border-end center-height pb-5">
            <div className="row mb-5">
              <div className="col-6 sideImage">
                <figure
                  className="mb-0 imageFigure"
                  style={{
                    background: `url(${data?.Image})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  {/* <img src={data?.Image} /> */}
                </figure>
              </div>
              <div className="col-6 sideText">
                <div className="mainText mb-3">
                  <h4 style={{ color: "#000" }}>{data?.name}</h4>
                  <h6 className="fw-bold" style={{ color: "green" }}>
                    {data?.price} RED {`($${data?.price / conversionRate})`}
                  </h6>
                </div>
                <div className="descText">
                  <h6 style={{ color: "#000" }}>Description</h6>
                  <p>{data?.description}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 collectionDetails">
                <h6 className="fw-bold" style={{ color: "#000" }}>
                  Collection Details
                </h6>
                <div className="row mb-3">
                  <div className="col-6 mb-2">
                    <FaCalendar /> Published by{" "}
                    <span className="fw-bold" style={{ color: "#000" }}>
                      {data?.publishedBy}
                    </span>{" "}
                  </div>
                  <div className="col-6 mb-2">
                    <FaClock /> Published{" "}
                    <span className="fw-bold" style={{ color: "#000" }}>
                      {momentTimeZone.utc(data?.createdAt).tz('America/Los_Angeles').format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="col-6 mb-2">
                    <FaCalendar /> Available From{" "}
                    <span className="fw-bold" style={{ color: "#000" }}>
                      {momentTimeZone.utc(data?.createdAt).tz('America/Los_Angeles').fromNow()}
                    </span>
                  </div>
                </div>
                <div className="p-3 mintNowText">
                  <h6 style={{ color: "#000" }} className="mb-3">
                    Buy now to get your NFT
                  </h6>
                  <h6 style={{ color: "#000" }} className="mb-3">
                    {data?.price} RED
                  </h6>
                  <h6 className="mb-3">
                    {momentTimeZone.utc(data?.createdAt).tz('America/Los_Angeles').format("DD-MM-YYYY")}{" "}
                    {momentTimeZone.utc(data?.createdAt).tz('America/Los_Angeles').format("h:mm a")}
                  </h6>
                  {card == "import" ? (
                    <div className="mintButton">
                      <button className="btn" onClick={() => nftImport(data)}>
                        Import Now
                      </button>
                      <button
                        className="btn mx-2"
                        onClick={() => setShow(true)}
                      >
                        Sell on Marketplace
                      </button>
                    </div>
                  ) : (
                    <div className="mintButton">
                      <button
                        className="btn"
                        // onClick={() => {
                        //   if (isKycVerified) {
                        //     buy(data);
                        //   } else {
                        //     kycErrorPopUp();
                        //   }
                        // }}
                      >
                        Buy Now
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header>
          <h5 className="" style={{ margin: "auto" }}>
            {" "}
            Sell NFT on user marketplace
          </h5>
          <span
            style={{ cursor: "pointer" }}
            className=" d-flex justify-content-end mb-2"
            onClick={handleClose}
          >
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                description: data?.description,
                name: data?.name,
                symbol: data?.symbol,
                price: data?.price,
                categoryId: data?.categoryId,
                nftId: data?._id,
              }}
              validationSchema={Schema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ errors, touched, values, setFieldValue, setTouched }) => (
                <Form className="sellNFT-form">
                  <div className="row align-items-start justify-content-center">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label htmlFor="propertyName">Name:</label>
                          <Field
                            disabled
                            name="name"
                            id="name"
                            placeholder="Enter Name"
                            className="form-control"
                          />
                          {errors.name && touched.name ? (
                            <div className="input-error">{errors.name}</div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="propertyName">Symbol:</label>
                          <Field
                            disabled
                            name="symbol"
                            id="symbol"
                            placeholder="Enter symbol"
                            className="form-control"
                          />
                          {errors.symbol && touched.symbol ? (
                            <div className="input-error">{errors.symbol}</div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="propertyName">
                            Price (Red Tokens):
                          </label>
                          <Field
                            name="price"
                            id="price"
                            placeholder="Enter price"
                            className="form-control"
                          />
                          {errors.price && touched.price ? (
                            <div className="input-error">{errors.price}</div>
                          ) : null}
                        </div>
                        <div className="col-6">
                          <label htmlFor="crowdSaletype">Category</label>
                          <Field
                            onChange={(e) => {
                              setFieldValue("categoryId", e.target.value);
                            }}
                            as="select"
                            disabled
                            name="categoryId"
                            id="categoryId"
                            className="form-control"
                          >
                            <option value="">--Select Category--</option>
                            {categories?.map((item, i) => {
                              return (
                                <option value={item?._id} key="i">
                                  {capitalize(item?.categoryName)}
                                </option>
                              );
                            })}
                          </Field>
                          {errors.categoryId && touched.categoryId ? (
                            <div className="text-danger">
                              {errors.categoryId}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-12 mb-3">
                          <label htmlFor="propertyName">Description:</label>
                          <textarea
                            name="description"
                            value={data?.description}
                            // type='textarea'
                            disabled
                            rows="4"
                            id="description"
                            placeholder="Enter Description"
                            className="form-control"
                          />
                          {/* {errors.description && touched.description ? (
                            <div className="input-error">{errors.description}</div>
                          ) : null} */}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="uploadImageContainern d-flex justify-content-center mb-4"
                            style={{
                              borderRadius: "11px",
                              border: "1px solid lightgray",
                            }}
                          >
                            <img
                              src={data?.Image}
                              alt="Avatar"
                              className="image"
                              style={{
                                height: "100px",
                                objectFit: "contain",
                                width: "100px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <Button
                        className="w-100 common-btn1"
                        variant="primary"
                        type="submit"
                      >
                        Sell
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NftDetail;
